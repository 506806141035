import { useState, useEffect, useRef } from 'react';

function useDebounce(callback, delay) {
  const [value, setValue] = useState('');
  const timeoutRef = useRef(null);

  const debounceValue = (newValue) => {
    setValue(newValue);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(newValue);
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return [value, debounceValue];
}

export default useDebounce;
