import React from "react";
import { Box, Typography } from "@mui/material";
import useStyle from "./styles";
import { Subheader } from "../RestaurantComponent";

const HerosectionAllStores = ({ imageSrc, headingText,onLocationChange}) => {
  const classes = useStyle();

  return (
    <Box className={classes.heroSection}>
      <img src={imageSrc} alt="Hero" className={classes.heroImage} />
      <div className={classes.overlay}></div>
      <Box className={classes.textOverlay}>
        <Typography variant="h6" className={classes.heroHeading}>
          {headingText}
        </Typography>
        <Subheader onLocationChange={onLocationChange} />
      </Box>
    </Box>
  );
};

export default HerosectionAllStores;
