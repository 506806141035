import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LeftCard from "../LeftCard/LeftCard";

import useStyles from "./styles";

const PlanCard = ({ data, index, total }) => {
  const isDIFM = data.name === "DIFM"; // Check if the plan is DIFM
  const navigate = useNavigate();
  const classes = useStyles()

  const handlePlanSubmission = () =>{
    navigate('/ListingBusiness', { state: { planName: data.name, planData: data, planPrice: data.price } })
  }

  return (
    <Box className={classes.parentContainer}>
      {index === 0 && (
        <Box sx={{ width: "550px" }} xs={8} md={4} className={classes.infoContainer}>
          <LeftCard />
        </Box>
      )}
      <Box
        sx={{
          width: `250px`, // Adjust the width to be narrow
          padding: "20px",
          paddingBottom: "20px",
          border: `1px solid ${isDIFM ? "#c0e341" : "#ddd"}`, // Border color for DIFM
          borderRadius: "8px",
          marginBottom: "20px",
          position: "relative", // To position the label absolutely
         
        }}
      >
        {isDIFM && (
          <Box
            sx={{
              position: "absolute",
              top: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#c0e341",
              color: "black",
              padding: "4px",
              borderRadius: "4px",
              fontWeight: "bold",
              fontSize: "11px",
            }}
          >
            Recommended Plan
          </Box>
        )}

        {/* <Typography
          variant="h6"
          sx={{ color: "#c0e341", fontWeight: "bold", textAlign: "center" }}
        >
          {data.name}
        </Typography> 
        */}

        {data.price.map((item, indexBox) => (
          <Box
            key={index}
            sx={{ height: "220px", borderBottom: "1px solid lightgray" }}
          >
            {indexBox === 0 ? (
              <Typography
                variant="h6"
                sx={{
                  color: "#c0e341",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {data.name}
              </Typography>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  color: "#c0e341",
                  fontWeight: "bold",
                  textAlign: "center",
                  visibility: "hidden",
                }}
              >
                {data.name}
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={{
                marginBottom: "10px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "18px",
                alignItems: "center",
                color: item.value ? "inherit" : "#00000", // Use white color if value is null
              }}
            >
              {item.value || "Free"}
              {/* Display "N/A" if value is null or empty */}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                color: "#808080",
                fontFamily: "Be Vietnam Pro",
                marginBottom: "80px",
                fontSize: "14px",
              }}
            >
              {item.description}
            </Typography>
            {/* {index < data.price.length - 1 && <Divider />} */}
          </Box>
        ))}

        {/* Divider above the button */}
        <Box sx={{ margin: "20px 0" }} />

        {/* Button */}
        <Button
          variant="contained"
          sx={{
            width: "100%",
            backgroundColor: isDIFM ? "black" : "white", // Button color for DIFM
            color: isDIFM ? "white" : "black",
            "&:hover": {
              backgroundColor: isDIFM ? "#333" : "#a1b400", // Button hover color for DIFM
            },
          }}
          onClick={handlePlanSubmission}
        >
          Sign up for {data.name}
        </Button>
      </Box>
    </Box>
  );
};

export default PlanCard;
