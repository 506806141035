import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";

const TermsAndConditions = ({ label, name, onChange, checked, error }) => {
  
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <FormControl component="fieldset" error={!!error}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            name={name}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 18 }, // Adjust this value to change checkbox size
            }}
          />
        }
        label={label}
        sx={{
          "& .MuiFormControlLabel-label": {
            fontSize: "0.775rem", // Adjust this value to your desired font size
            fontWeight: "bold",
          },
        }}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default TermsAndConditions;
