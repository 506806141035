import React, { useState, useContext } from "react";
import { LoginHeader } from "../../components/Header";
import Footer from "../../components/HomeScreen/Footer";
import FormCard from "../../components/FormCard/FormCard";
import DoubleTextFieldRow from "../../components/DoubleTextField/DoubleTextField";
import SingleTextField from "../../components/Textfield/TextField";
import PhoneTextField from "../../components/PhoneTextField/PhoneTextField";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import { sendEmail } from "../../utils/emailService";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import BecomeADriver from "../../assets/images/BecomeADriveh.svg";
import HerosectionLoginPages from "../../components/HeroSectionLoginPage/HeroSectionLoginPage";
import PlaceholderComponent from "../../components/Placeholder/Placeholder";
import TermsAndConditions from "../../components/Terms&Condition/Terms&Condition";
import BusinessListingmiddd from "../../assets/images/BLM.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Dropdown from "../../components/DropDown/DropDown";
import Header from "../../components/Header/Header";
import UserContext from "../../context/User";
import { useEffect } from "react";


const ListingBusiness = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useContext(UserContext);

  const { planData } = location.state || {};

  const [formData, setFormData] = useState({
    storeName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    city: "",
    postalAddress: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
    plan: planData?.name || "",
  });

  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { password, confirmPassword } = formData;

    if (!formData.termsAccepted) {
      setAlert({
        open: true,
        severity: "error",
        message: "You must accept the terms and conditions.",
      });
      return;
    }

    if (password !== confirmPassword) {
      setAlert({
        open: true,
        severity: "error",
        message: "Passwords do not match.",
      });
      return;
    }

    setLoading(true);
    const templateParams = {
      ...formData,
      role: "Business Listing",
      isRider: false,
    };
    sendEmail("template_ufhqmzs", templateParams)
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        setAlert({
          open: true,
          severity: "success",
          message: "Form Submitted Successfully",
        });
        setFormData({
          storeName: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          city: "",
          postalAddress: "",
          password: "",
          confirmPassword: "",
          plan: "",
        });
        // navigate('/PaymentPage', { state: { planData } });
        navigate('/SubmitFormPage');
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        setAlert({
          open: true,
          severity: "error",
          message: "Form Not Submitted",
        });
      })
      .finally(() => {
        setLoading(false);
      });
    
      

  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ ...alert, open: false });
  };

  const handleInfoClick = () => {
    navigate("/pricingPage");
  };

  const formComponent = (
    <FormCard heading="Get Started Today!">
      <form onSubmit={handleSubmit}>
        <Dropdown
          label="Select Plan"
          name="plan"
          placeholder="Choose your plan"
          options={["DIY", "Enterprise", "DIFM"]}
          value={formData.plan}
          onChange={handleChange}
          onInfoClick={handleInfoClick}
          planData={planData}
        />

        <SingleTextField
          placeholder="Store Name"
          name="storeName"
          value={formData.storeName}
          onChange={handleChange}
          required
        />
        <DoubleTextFieldRow
          placeholder1="First Name"
          name1="firstName"
          value1={formData.firstName}
          placeholder2="Second Name"
          name2="lastName"
          value2={formData.lastName}
          onChange={handleChange}
          required
        />
        <PhoneTextField
          placeholder="Enter your phone number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
        <SingleTextField
          placeholder="Email Address"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <DoubleTextFieldRow
          placeholder1="City"
          name1="city"
          value1={formData.city}
          placeholder2="Post Code"
          name2="postalAddress"
          value2={formData.postalAddress}
          onChange={handleChange}
          required
        />
        <SingleTextField
          placeholder="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          isPassword={true}
          required
        />
        <SingleTextField
          placeholder="Confirm Password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          isPassword={true}
          required
        />
        <TermsAndConditions
          label="I Agree To The Terms And Conditions And Privacy Policy"
          name="termsAccepted"
          checked={formData.termsAccepted} // Pass checkbox state
          required
          onChange={handleChange}
        />
        <SubmitButton label="Submit" type="submit" loading={loading} />
      </form>
    </FormCard>
  );

  return (
    <div>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <HerosectionLoginPages
        imageSrc={BecomeADriver}
        headingText="A delivery solution that means business."
        descriptionText="Attract more customers in more neighbourhoods and better connect with existing ones."
      />
      <PlaceholderComponent
        imageSrc={BusinessListingmiddd}
        formComponent={formComponent}
      />
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert severity={alert.severity} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListingBusiness;
