import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import useStyle from './styles';
import { FaPlus, FaMinus } from "react-icons/fa6";

const AskQuestion = ({ title, description, dangerouslySetInnerHTML }) => {
    const [expanded, setExpanded] = useState(false);
    const classes = useStyle();

    const handleToggle = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Box>
            <Accordion className={classes.accordion} expanded={expanded} onChange={handleToggle}>
                <AccordionSummary
                    expandIcon={expanded ? <FaMinus /> : <FaPlus />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className={classes.accordionHeader}
                >
                    <Typography sx={{fontWeight: '500'}}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionText}>
                    {dangerouslySetInnerHTML ? (
                        <Typography
                            component="div"
                            dangerouslySetInnerHTML={{ __html: description }} 
                        />
                    ) : (
                        <Typography>{description}</Typography>
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default AskQuestion;