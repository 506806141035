import React, { useState, useRef, useEffect } from 'react';
import useStyles from './styles'; 
import ArrowDown from '../../assets/images/vectordropdown.svg'; 
import ArrowUp from '../../assets/images/arrowupward.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

const Dropdown = ({ label, name, placeholder, options, value, onChange, onInfoClick, planData }) => {
  const classes = useStyles();
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || (planData?.name || ''));
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onChange({ target: { name, value: option } }); // Simulate event object
    setShowOptions(false);
  };

  const handleDropdownClick = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className={classes.container}>
      <label className={classes.label}>{label}</label>
      <div className={classes.dropdownWrapper}>
        <div ref={dropdownRef} className={classes.dropdown} onClick={handleDropdownClick}>
          <span className={selectedOption ? '' : classes.placeholder}>
            {selectedOption || placeholder}
          </span>
          <img
            src={showOptions ? ArrowUp : ArrowDown}
            alt="dropdown-arrow"
            className={classes.arrow}
          />
          {showOptions && (
            <div className={classes.dropdownContent}>
              {options.map((option, index) => (
                <div key={index} onClick={() => handleOptionClick(option)}>
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
        <Tooltip title="See pricing plans" arrow>
          <InfoOutlinedIcon className={classes.infoIcon} onClick={onInfoClick} />
        </Tooltip>
      </div>
    </div>
  );
};

export default Dropdown;