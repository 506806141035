import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Rating,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery, gql, useApolloClient } from "@apollo/client";
import useStyle from "./style";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { IoLocationOutline } from "react-icons/io5";
import { IoCarSportOutline } from "react-icons/io5";
import { fetchUniqueCities } from "../../../apollo/server";
// import { Link as RouterLink } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchRestaurantHome from "../../RestaurantComponent/SearchRestaurant/SearchRestaurantHome";
import { ReactComponent as EmptyIcon } from "../../../assets/images/empty-search.svg";
import { combinedRestaurantSearch } from "../../../apollo/server";
import { useLocationContext } from "../../../context/Location";
import storePlaceholder from "../../../assets/images/storePlaceholder.png";
import Tooltip from "@mui/material/Tooltip";

const FETCH_UNIQUE_CITIES = gql`
  ${fetchUniqueCities}
`;

// const FETCH_RESTAURANT_BY_CITY = gql`
//   ${fetchRestaurantsByCityName}
// `;

const COMBINED_RESTAURANT_SEARCH = gql`
  ${combinedRestaurantSearch}
`;
export default function Neighbourhood({
  fromRestaurant = false,
  HideShowAll = true,
}) {
  const client = useApolloClient();

  const { searchedCity, selectedCity, setSelectedCity } = useLocationContext();

  const classes = useStyle();
  const [cities, setCities] = useState([]);
  const [showAll, setShowAll] = useState(false); // New state to toggle "See All" functionality
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  // API Handlers
  const onCitiesFetched = (data) => {
    const cityList = data?.uniqueCityList ?? [];

    setCities(cityList);

    if (cityList.length > 0) {
      if (fromRestaurant) return;
      if (selectedCity) return;
      !fromRestaurant && setSelectedCity(cityList[0]);
    }
  };

  // const {
  //   data: restaurantsData,
  //   loading: restaurantsLoading,
  //   error: restaurantsError,
  // } = useQuery(FETCH_RESTAURANT_BY_CITY, {
  //   variables: { cityName: selectedCity.cityName },
  //   skip: !selectedCity?.cityName,
  // });

  // const {
  //   data: restaurantsData,
  //   loading: restaurantsLoading,
  //   error: restaurantsError,
  // } = useQuery(COMBINED_RESTAURANT_SEARCH, {
  //   variables: { city: selectedCity },
  //   skip: !selectedCity.lat || !selectedCity.cityName,
  // })

  const {
    data: restaurantsData,
    loading: restaurantsLoading,
    error: restaurantsError,
  } = useQuery(COMBINED_RESTAURANT_SEARCH, {
    variables: {
      cityName: selectedCity.cityName,
      latitude: selectedCity.lat,
      longitude: selectedCity.lng,
      shopType: null, // or pass a shopType if needed
    },
    skip: !selectedCity.lat && !selectedCity.cityName,
  });

  const { loading: citiesLoading, error: citiesError } = useQuery(
    FETCH_UNIQUE_CITIES,
    { onCompleted: onCitiesFetched }
  );

  useEffect(() => {
    console.log({ searchedCity });
    if (searchedCity.cityName === "") return;
    if (isNaN(searchedCity.lat)) return;
    if (searchedCity.cityName === selectedCity.cityName) return;

    if (
      searchedCity &&
      !cities.some((city) => city.cityName === searchedCity.cityName)
    ) {
      // const citySet = new Set(
      //   cities.length > 0 ? [{cityName: searchedCity.cityName}, ...cities] : [searchedCity.cityName]
      // );

      let citySet =
        cities.length > 0
          ? [...cities].filter((city) => {
              if (searchedCity?.lat !== 0 && city?.lat !== 0) {
                if (searchedCity?.lat !== city?.lat) {
                  return true;
                }
              } else {
                if (
                  !searchedCity?.cityName ||
                  city.cityName !== searchedCity.cityName
                ) {
                  return true;
                }
              }

              return false;
            })
          : [searchedCity];

      setCities([searchedCity, ...citySet]);

      console.log(
        "🚀 ~ in memory cache being hit in the neighbourhood component ",
        client.cache
      );

      if (searchedCity.lat && searchedCity.cityName) {
        console.log("🚀 ~ useEffect ~ searchedCity:", searchedCity);
        client.cache.modify({
          fields: {
            uniqueCityList(existingCities = []) {
              console.log({ existingCities });

              const new_cities = [...existingCities].filter((city) => {
                if (searchedCity?.lat !== 0 && city?.lat !== 0) {
                  if (searchedCity?.lat !== city?.lat) {
                    return true;
                  }
                } else {
                  if (
                    !searchedCity?.cityName ||
                    city.cityName !== searchedCity.cityName
                  ) {
                    return true;
                  }
                }

                return false;
              });
              return [
                { __typename: "UniqueCitiesResponse", ...searchedCity },
                ...new_cities,
              ];
            },
          },
        });
      }
    } //else {
    //   // If searchedCity already exists in the list, place it at the top
    //   let updatedCities = [...cities].filter(city => city.cityName !== searchedCity.cityName);
    //   setCities([searchedCity, ...updatedCities]);
    // }
    setSelectedCity(searchedCity);

    // eslint-disable-next-line
  }, [searchedCity]);

  const isValidLogo = (logo) => {
    // Adjust this condition based on your specific criteria
    return logo !== "" && !logo?.startsWith("/static/media");
  };

  const searchRestaurants = (searchText) => {
    const data = [];
    restaurantsData?.combinedRestaurantSearch?.restaurants?.forEach(
      (restaurant) => {
        const regex = new RegExp(
          searchText.replace(/[\\[\]()+?.*]/g, (c) => "\\" + c),
          "i"
        );
        const result = restaurant.name.search(regex);
        if (result < 0) {
          const result = restaurant.categories.some((category) => {
            const result = category.title.search(regex);
            if (result < 0) {
              const result = category.foods.some((food) => {
                const result = food.title.search(regex);
                return result > -1;
              });
              return result;
            }
            return true;
          });
          if (!result) return;
        }
        data.push(restaurant);
      }
    );
    return data;
  };

  // const filteredRestaurants = search
  //   ? searchRestaurants(search)
  //   : restaurantsData?.fetchRestaurantsByCityName?.restaurants || [];

  const filteredRestaurants = search
    ? searchRestaurants(search)
    : restaurantsData?.combinedRestaurantSearch?.restaurants || [];

  const handleNavigate = (restaurant) => {
    navigate(`/store/${restaurant.slug}`, {
      state: {
        id: restaurant._id,
        name: restaurant.name,
        image: restaurant.image,
        slug: restaurant.slug,
      },
    });
  };

  console.log(selectedCity);

  return (
    <Box className={classes.neighbourhood_section}>
      <Container>
        <Box className={classes.neighbourhood_title}>
          <Typography variant="h2">Find us in your neighbourhood</Typography>
          <Typography>
            Discover this month’s top stores and suppliers near you, delivering
            quality products straight to your doorstep.
          </Typography>
        </Box>

        <Box sx={{ overflow: "auto", whiteSpace: "nowrap", mb: 2 }}>
          <SearchRestaurantHome search={search} setSearch={setSearch} />
        </Box>

        <Box
          sx={{ overflow: "auto", whiteSpace: "nowrap", mb: 2 }}
          className={classes.customScrollbar}
        >
          {citiesLoading ? (
            <Typography variant="h5" align="center">
              Loading cities...
            </Typography>
          ) : citiesError ? (
            <Typography variant="h5" align="center">
              Error loading cities: {citiesError.message}
            </Typography>
          ) : (
            cities.map((city, index) => {
              return (
                <Box
                  key={index}
                  component="span"
                  onClick={() => {
                    setSelectedCity(city);
                  }}
                  sx={{
                    display: "inline-block",
                    px: 2,
                    py: 1,
                    mr: 1,
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    cursor: "pointer",
                    backgroundColor:
                      selectedCity.cityName === city.cityName
                        ? "#c0e341"
                        : "#f0f0f0",
                    color:
                      selectedCity.cityName === city.cityName
                        ? "white"
                        : "inherit",
                    "&:hover": {
                      backgroundColor:
                        selectedCity.cityName === city.cityName
                          ? "#c0e341"
                          : "#e0e0e0",
                    },
                  }}
                >
                  <Typography>{city?.cityName ?? "-"}</Typography>
                </Box>
              );
            })
          )}
        </Box>

        {selectedCity && (
          <Box sx={{ mt: 2 }}>
            {restaurantsLoading ? (
              <Typography variant="h5" align="center" sx={{ mt: 4 }}>
                Loading stores...
              </Typography>
            ) : restaurantsError ? (
              <Typography variant="h5" align="center" sx={{ mt: 4 }}>
                Error: {restaurantsError.message}
              </Typography>
            ) : filteredRestaurants.length > 0 ? (
              <>
                <Grid container spacing={2}>
                  {(!HideShowAll
                    ? filteredRestaurants
                    : filteredRestaurants.slice(0, 6)
                  ).map((restaurant, i) => (
                    <Grid item lg={4} sm={6} xs={12} key={i}>
                      <Box
                        className={classes.restaurant_card}
                        onClick={() => handleNavigate(restaurant)}
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        <Box
                          className={classes.restaurant_img}
                          sx={{
                            height: 0,
                            paddingTop: "56.25%",
                            position: "relative",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={restaurant.image}
                            alt={restaurant.name}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            top: "8.8rem",
                            left: "18%",
                            transform: "translateX(-50%)",
                            width: "70px",
                            height: "50px",
                            borderRadius: "10%",
                            overflow: "hidden",
                            border: "2px solid white",
                            backgroundColor: "white",
                          }}
                        >
                          <img
                            src={
                              isValidLogo(restaurant.logo)
                                ? restaurant.logo
                                : storePlaceholder
                            }
                            alt={`${restaurant.name} logo`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                        <Box className={classes.restaurant_card_content}>
                          <Box className={[classes.restaurant_card_box]}>
                            <Tooltip title={restaurant.name}>
                              <Typography
                                variant="h3"
                                sx={{
                                  marginBottom: "5px !important",
                                  marginTop: "5px !important",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%", // Adjust to fit within the card
                                }}
                              >
                                {restaurant.name}
                              </Typography>
                            </Tooltip>
                            <Stack
                              direction="row"
                              alignItems="center"
                              marginLeft="10px"
                            >
                              <Rating
                                name="rating"
                                value={1}
                                precision={0.1}
                                max={1}
                                sx={{ fontSize: "18px" }}
                                readOnly
                              />
                              <Typography sx={{ marginLeft: "5px" }}>
                                {restaurant.rating}(
                                {restaurant.reviewData.total})
                              </Typography>
                            </Stack>
                          </Box>
                          <Box className={classes.flexRow}>
                            <Typography variant="h6">
                              {restaurant.shopType || "Landscape Supplies"}
                            </Typography>
                            <Stack direction="row">
                              <IoLocationOutline size={19} color="#5b606e" />
                              <Typography>{restaurant.city}</Typography>
                            </Stack>
                          </Box>
                        </Box>
                        <Box className={classes.restaurant_card_footer}>
                          <Box className={classes.delivery_time}>
                            <AccessTimeIcon />
                            <Typography>
                              {restaurant.deliveryTime} mins
                            </Typography>
                          </Box>
                          <Box className={classes.delivery_time}>
                            <IoCarSportOutline size={19} />
                            <Typography>${restaurant.tax}</Typography>
                          </Box>
                          <Box className={classes.delivery_time}>
                            <Typography>
                              Min Order ${restaurant.minimumOrder}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {HideShowAll && filteredRestaurants.length > 6 && !showAll && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ mt: 2 }}
                      align="center"
                      onClick={() => {
                        setShowAll(true);
                        navigate("/store-list", {
                          state: { city: selectedCity },
                        });
                      }}
                    >
                      See all <KeyboardArrowRightIcon />
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center">
                <EmptyIcon />
                <Typography variant="h5" align="center" sx={{ mt: 4 }}>
                  No store found.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}
