import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row", // Default to row direction
    justifyContent: "space-between",
    alignItems: "center",
    padding: "80px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Stack items in a column on small screens
      alignItems: "flex-start", // Align items to the start on small screens
      padding: "0px",
    },
  },
  imageContainer: {
    flex: 1,
    marginRight: "20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px", // Remove right margin on small screens
      marginBottom: "20px", // Add bottom margin to separate from the form
      width: "100%",
    },
  },
  image: {
    width: "90%",
    height: "auto",
    maxWidth: "600px",
    borderRadius: "8px",
  },
  smallerImage: {
    width: "80%",
    maxWidth: "400px",
  },
  imageText: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    fontWeight: '500',
  },
  formContainer: {
    flex: 1,
    marginLeft: "20px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px", // Remove left margin on small screens
    },
  },
}));

export default useStyles;
