import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useStyles from './style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PhoneTextField = ({ value, onChange, placeholder, name }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PhoneInput
      placeholder={placeholder || "Enter phone number"}
      country={"au"} // Update country code as needed
      value={value}
      onChange={phone => onChange({ target: { name, value: phone } })}
      inputProps={{
        name: name
      }}
      containerStyle={{
        textAlign: "center",
        marginBottom: "20px",
        width: '100%', // Full width to adapt to container
      }}
      inputStyle={{
        paddingLeft: isSmallScreen ? 35 : 40, // Adjust padding for flag
        width: '100%',
        maxWidth: '500px',
        height: '42px',
        borderRadius: '8px', // Square-like shape with rounded corners
        border: "1px solid #D0D0D0", // Thin border
        backgroundColor: "#ffff",
        boxShadow: "1px 4px 9px 0px #0000001A",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#fffff',
      }}
      buttonStyle={{
      background: 'none', // Remove default background
        border: 'none', // Remove default border
        borderRadius: '8px', // Rounded flag button
        width: '40px',
        height: '100%', // Match the input height
        padding: 0, // Remove padding
        margin: 0, // Remove margin
      }}
      dropdownStyle={{
        borderRadius: '8px', // Rounded dropdown
        boxShadow: '1px 4px 9px 0px #0000001A',
        backgroundColor: '#fffff',
      }}
      inputClass={`${classes.phoneInput} phone-input-container`}
    />
  );
};

export default PhoneTextField;
