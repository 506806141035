import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#F5F5F5',
    padding: '5px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '50px'
  },
  formControl: {
    width: '100%',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  iconLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem', // Make the font size smaller
    marginRight: '16px', // Reduce margin between radio buttons
    
  },
  iconPaypal: {
    width: '21px', // Smaller icon size
    height: '21px',
    marginRight: '8px',
  },
  iconPayByCard: {
    width: '64.11px', // Smaller icon size
    height: '30.11px',
    marginRight: '4px',
  },
  lockIcon: {
    marginLeft: '8px',
    fontSize: '16px', // Smaller lock icon size
  },
}));

export default useStyles;
