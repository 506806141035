import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    minHeight: "64px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.light,
  },
  font700: {
    fontWeight: 700,
  },
  ml: {
    marginLeft: "8px",
  },
  userTitle: {
    marginLeft: "8px",
    textTransform: "uppercase",
  },
  menu: {
    marginTop: theme.spacing(3),
  },
  // menuItem: {
  //   padding: "14px 40px",
  //   width: "100%",
  //   borderTop: "1px solid #737373",
  //   "& .MuiTouchRipple-root span": {
  //     backgroundColor: "rgba(0,0,0,0.3)",
  //   },
  // },
  linkDecoration: {
    textDecoration: "none",
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cartText: {
    marginLeft: 2,
    fontWeight: 700,
  },
  imageIcon: {
    height: "100%",
    display: "inline-flex",
    backgroundColor: "red",
  },
  iconRoot: {
    textAlign: "center",
    height: "50px",
    width: "50px",
  },
  icon: {
    color: theme.palette.common.black,
  },
  location_card: {
    maxWidth: "400px",
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 30px #0000001a",
    zIndex: "9999",
    borderRadius: "10px",
    top: "15px !important",
    marginLeft: "130px !important",
  },
  search_location: {
    padding: "20px",
  },
  recent_location: {
    padding: "20px",
    "& p": {
      color: "#1e1a18",
      marginLeft: "10px",
      fontWeight: "600",
    },
  },
  recent_location_history: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
    marginTop: "10px",
    gap: "5px",
    "& svg": {
      fontSize: "18px",
    },
    "& span": {
      fontSize: "14px",
      color: "#333",
    },
  },
  location_btn: {
    backgroundColor: "transparent !important",
    color: "#000",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  location_icon: {
    width: "20px",
    height: "20px",
  },
  user_login_menu: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  toggle_icon: {
    color: "#333",
    cursor: "pointer",
  },
  like_icon: {
    "& svg": {
      color: "#1e1a18",
      cursor: "pointer",
    },
  },
  user_login: {
    display: "flex",
    color: "#333",
    alignItems: "center",
    "& img": {
      width: "30px",
    },
  },
  user_link: {
    textDecoration: "unset",
    cursor: "pointer",
    color: "#333",
    fontWeight: "500",
  },
  locate_btn: {
    position: "absolute",
    left: "auto",
    backgroundColor: "#c0e342 !important",
    color: "#fff",
    borderRadius: "50px",
    textTransform: "capitalize",
    right: "0",
    bottom: "0",
    top: "0",
    padding: "15px",
    //color: "#000",
    "& svg": {
      marginRight: "10px",
    },
  },
  location_detail: {
    width: "100%",
    position: "relative",
    "& input": {
      padding: "10px 10px 10px 20px",
      color: "#000",
    },
    "& fieldset": {
      borderRadius: "50px",
      borderColor: "#33333361 !important",
    },
  },
}));

export default useStyles;
