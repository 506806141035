// import { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import useStyle from "./style";
//import { useTranslation } from "react-i18next";
import googleplaybtn from "../../../assets/images/google-play.svg";
import appstorebtn from "../../../assets/images/app-store.svg";
import orderImg from "../../../assets/images/orderImg.png";

export default function Order() {
  //const { t } = useTranslation();
  const classes = useStyle();
  //   const theme = useTheme();

  //   const [value, setValue] = useState(0);

  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };

  return (
    <>
      <Container>
        <Box className={classes.step_title}>
          <Typography>Ready when you are </Typography>
          <Typography variant="h2">At your fingertips</Typography>
        </Box>
      </Container>
      <Box className={classes.order_section}>
        <Container>
          <Grid container spacing={2} sx={{ position: "relative" }}>
            <Grid item lg={6}>
              <Box>
                <Typography variant="h3">Order anywhere, anytime.</Typography>
                <Typography>
                  Experience the convenience of having all your home improvement
                  and landscaping supplies gathered together in one place, and
                  getting them delivered to your doorstep now.
                </Typography>
                <Typography>
                  Explore a wide range of products including your favourite
                  plants, turf, soil, mulch, pavers, timber, tiles, paint,
                  furniture, BBQ's and more.
                </Typography>
                <Typography>
                  Download the Hedge App today and bring your home to life.
                </Typography>
                <Box className={classes.download_btn}>
                  <img src={appstorebtn} alt="appstorebtn" />
                  <img src={googleplaybtn} alt="googleplaybtn" />
                </Box>
              </Box>
            </Grid>
            <Box className={classes.order_img}>
              <img src={orderImg} alt="orderImg" />
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
