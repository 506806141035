import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { PropagateLoader } from 'react-spinners';
import useStyles from './styles';

const GoogleConnectButton = ({ text, onClick, loading, googleLogoSrc }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>{text}</Typography>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={onClick}
        disabled={loading}
      >
        {loading ? (
          <PropagateLoader color="#4285F4" size={8} margin={2} />
        ) : (
          <>
            <img src={googleLogoSrc} alt="Google" className={classes.logo} />
            <span className={classes.buttonText}>Connect with Google</span>
          </>
        )}
      </Button>
    </Box>
  );
};

export default GoogleConnectButton;