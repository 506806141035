import React from "react";
import { LoginHeader, Header } from "../../components/Header";
import Herosection from "../../components/HeroSection/HeroSection";
import SustainabilityBanner from "../../../src/assets/images/SustainabilityBanner.png";
import { Card, CardContent, Typography, Box } from "@mui/material";
import UserContext from "../../context/User";
import { useContext } from "react";
import MiddleContent from "../../components/MiddleComponent/MiddleComponent";
import SustainabilityPageFirst from "../../assets/images/SustainabilityPageFirst.png";
import SustainabilityPageSecond from "../../assets/images/SustainabilityPageSecond.png";
import SustainabilityPageThird from "../../assets/images/SustainabilityPageThird.png";
import SustainabilityPageFourth from "../../assets/images/SustainabilityPageFourth.png";
import GradientFooter from "../../components/GradientFooter/GradientFooter";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/HomeScreen/Footer";
import { useEffect } from "react";

const SustainabilityPage = () => {
  const { isLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  const handleButton1Click = () => {
    navigate("/business-partner");
  };

  const handleButton2Click = () => {
    navigate("/delivery");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Card
        sx={{
          position: "absolute",
          right: "10%",
          top: "23rem",
          transform: "translateY(-50%)",
          width: "430px",
          borderRadius: "8px",
          zIndex: 10,
          display: {
            xs: "none", // Hide on extra-small screens
            sm: "none", // Hide on small screens
            md: "block", // Show on medium and larger screens
          },
        }}
      >
        <CardContent>
          <Typography
            variant="body2"
            sx={{
              color: "#808080",
              fontSize: "0.775rem",
              "& p": {
                marginBottom: "0.3em",
              },
            }}
          >
            <h2 style={{ color: "black" }}>
              Working towards a sustainable future
            </h2>
            <br />
            <p>
              In the face of unprecedented challenges in history, such climate
              change and pollution, Hedge is committed to playing a more active
              role in addressing these issues and safeguarding the planet for
              future generations.
            </p>
            <br />
            <p>
              We believe that by working together, we can make a difference.
            </p>
          </Typography>
        </CardContent>
      </Card>
      <Herosection
        imageSrc={SustainabilityBanner}
        headingText="Sustainability"
        descriptionText="Our journey to greener deliveries"
      />
      <Box sx={{ textAlign: "center", marginTop: "70px" }}>
        <Typography
          variant="h5"
          sx={{ color: "black", fontWeight: "bold", marginBottom: "10px" }}
        >
          Our vision for more sustainable deliveries
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "grey", fontSize: "1rem", marginBottom: "40px" }}
        >
          With tomorrow in mind
        </Typography>
      </Box>
      <MiddleContent
        heading="Our vision is to make your experience as sustainable as possible"
        description="Utilising an existing network of vehicles to offer an on-demand delivery solution is our approach to minimising our impact on the planet."
        mainImage={SustainabilityPageFirst}
        reverseMiddleContent={false}
      />
      <MiddleContent
        heading="Offsetting our carbon emissions"
        description="The majority of our products are supplied from local stores, growers, and suppliers. Opting for local sourcing not only reduces our carbon footprint but also supports thriving local businesses, creates local jobs, and enhances economic opportunities in our communities."
        mainImage={SustainabilityPageSecond}
        reverseMiddleContent={true}
      />
      <MiddleContent
        heading="Reducing our carbon emissions through efficiency"
        description="Driving hundreds of km to deliver your products doesn't align with our sustainability goals. Instead, we're focused on creating an ecosystem of local drivers to minimise transportation distances and reduce our environmental impact."
        mainImage={SustainabilityPageThird}
        reverseMiddleContent={false}
      />
      <MiddleContent
        heading="Working towards a zero-waste future."
        description="Together with our partners, suppliers, and customers, we look for ways to reduce waste going to landfill."
        mainImage={SustainabilityPageFourth}
        reverseMiddleContent={true}
      />

      <GradientFooter
        heading="Sign up and get started."
        description="We’re here to help you take control of your online orders and upgrade your customers to a better delivery experience."
        button1Text="Become a Vendor"
        button2Text="Become a Driver"
        onButton1Click={handleButton1Click}
        onButton2Click={handleButton2Click}
      />
      <Footer />
    </>
  );
};

export default SustainabilityPage;
