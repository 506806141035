import React, { useState, useEffect } from "react";

import useStyle from "./styles";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Container,
  IconButton,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import banner from "../../../assets/images/banner-img.png";
import Shop from "../../../assets/icons/Shop.svg";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Subheader from "../../RestaurantComponent/Subheader/Subheader";
import { Link as RouterLink } from "react-router-dom";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import useDebounce from "../../../hooks/useDebounce";
import InputAdornment from "@mui/material/InputAdornment";
import SyncLoader from "react-spinners/SyncLoader";
import LocationIcon from "../../../assets/icons/LocationIcon";
import { useLocation } from "../../../hooks";
import { LocationContext, useLocationContext } from "../../../context/Location";

const autocompleteService = { current: null };

const HeroSectionBanner = ({ isBannerOpen, setIsBannerOpen }) => {
  const classes = useStyle();

  return (
    <Box className={classes.alertBox}>
      <Collapse in={isBannerOpen}>
        <Box className={classes.alertContainer}>
          <Alert
            className={classes.alertContent}
            icon={false}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsBannerOpen(false);
                }}
              >
                <CloseIcon
                  fontSize="inherit"
                  sx={{ height: "26px !important", width: "25px !important" }}
                />
              </IconButton>
            }
          >
            <Box className={classes.alertButton}>
              <img src={Shop} alt="shop" sx={{ marginLeft: "30px" }} />
              <Typography sx={{ marginLeft: "20px" }}>
                Do you need a vendor seller account?
              </Typography>
              <RouterLink
                to={"/ListingBusiness"}
                className={classes.linkDecoration}
              >
                <Button variant="contained" className={classes.bannersignupBtn}>
                  Signup Now
                </Button>
              </RouterLink>
            </Box>
          </Alert>
        </Box>
      </Collapse>
    </Box>
  );
};

const Herosection = ({
  loading,
  coordinates,
  locationSelected,
  handleLocationChange,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const [value, setValue] = useState(null);
  const { getCurrentLocation } = useLocation();
  const [Loading, setLoading] = useState(false);
  const [options, setOptions] = useState(["Loading ..."]);

  const { setSearchedCity} = useLocationContext(LocationContext);
 


  const [searchTerm, setSearchTerm] = useDebounce((debounced_value) => {
    if (searchTerm === "") {
      setOptions(debounced_value ? [debounced_value] : []);
      return undefined;
    }

    autocompleteService.current.getPlacePredictions(
      { input: debounced_value },
      (results) => {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    );
  }, 1000); // Adjust the delay as needed (1000 ms = 1 second)

  const handleGetCurrentLocation = () => {
    getCurrentLocation((error, data) => {
      console.log("getting current location", data);
      setLoading(false);
      if (error) {
        console.error("Error getting location:", error);
        return;
      }
      setSearchTerm(data.deliveryAddress || "");
      handleLocationChange({
        latitude: data.latitude,
        longitude: data.longitude,
        address: data.deliveryAddress,
      });


      setSearchedCity({
        lat: data.latitude,
        lng: data.longitude,
        cityName: data.deliveryAddress,
      });


     
      // console.log("🚀 ~ inmemorycache being hit in the herosection component ", client.cache)/
      // client.cache.modify({
      //   fields: {
      //     uniqueCityList(existingCities = []) {
           
      //       const new_cities = [...existingCities].filter(
      //         (city) =>
      //           !data?.deliveryAddress ||
      //           city?.cityName !== data.deliveryAddress
      //       );

      //       return [
      //         {
      //           __typename: "UniqueCitiesResponse",
      //           lat: data.latitude,
      //           lng: data.longitude,
      //           cityName: data.deliveryAddress,
      //         },
      //         ...new_cities,
      //       ];
      //     },
      //   },
      // });
    });
      
  };

  // useEffect(() => {
  //   handleGetCurrentLocation();
  //   // eslint-disable-next-line
  // }, []);

  const handleChange = (event_value) => {
    setSearchTerm(event_value);
  };

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {}, []);

  return (
    <Box>
      <HeroSectionBanner
        isBannerOpen={isBannerOpen}
        setIsBannerOpen={setIsBannerOpen}
      />
      <Box className={classes.hero_section}>
        <Box className={classes.banner_img}>
          <img src={banner} alt="banner" />
          <Box className={classes.banner_title}>
            <Container>
              <Typography variant="h1">
                All your hardware and landscaping supplies,
                <Typography variant="h1">delivered.</Typography>
              </Typography>

              {!locationSelected ? (
                <Box className={classes.search_stores}>
                  <Autocomplete
                    id="google-map-demo"
                    // sx={{ width: 500 }} // Increased width
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.description
                    }
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    getOptionDisabled={(option) => option === "Loading ..."}
                    value={loading ? "Loading ..." : searchTerm}
                    onChange={(event, newValue) => {
                      setOptions(newValue ? [newValue, ...options] : options);
                      if (value !== newValue) {
                        setValue(newValue);
                      }
                      if (newValue) {
                        const geocoder = new window.google.maps.Geocoder();
                        geocoder.geocode(
                          { placeId: newValue.place_id },
                          (results) => {
                            if (results[0]) {
                              const { lat, lng } = results[0].geometry.location;
                              handleLocationChange({
                                latitude: lat(),
                                longitude: lng(),
                                address: newValue.description,
                              });
                            }
                          }
                        );
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleChange(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Find Suppliers In Your Area"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <InputAdornment position="end">
                                {Loading ? (
                                  <SyncLoader
                                    color={theme.palette.primary.main}
                                    size={5}
                                    speedMultiplier={0.7}
                                    margin={1}
                                  />
                                ) : (
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setLoading(true);
                                      handleGetCurrentLocation();
                                      console.log("Locate me tapped");
                                    }}
                                    style={{
                                      color: "#AAC810",
                                      textTransform: "none",
                                      // padding: "2px 8px",
                                      display: "flex", // Use flexbox to align items
                                      alignItems: "center", // Vertically center the text and icon
                                      minWidth: "unset",
                                    }}
                                  >
                                    Locate me
                                    <LocationIcon />
                                  </Button>
                                )}
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                    )}
                    renderOption={(props, option) => {
                      const matches =
                        option.structured_formatting
                          ?.main_text_matched_substrings;
                      const parts = parse(
                        option.structured_formatting?.main_text,
                        matches?.map((match) => [
                          match.offset,
                          match.offset + match.length,
                        ]) ?? []
                      );

                      return (
                        <li {...props}>
                          <Grid container alignItems="center" spacing={2}>
                            {parts.length < 1 ? (
                              <Grid item>
                                <p
                                  style={{
                                    color: theme.palette.text.secondary,
                                  }}
                                >
                                  Loading...{" "}
                                </p>
                              </Grid>
                            ) : parts.length === 1 && !parts[0].text ? (
                              <Grid item>
                                <p
                                  style={{
                                    color: theme.palette.text.secondary,
                                  }}
                                >
                                  Loading...{" "}
                                </p>
                              </Grid>
                            ) : (
                              <>
                                <Grid item>
                                  <LocationOnIcon
                                    sx={{ color: theme.palette.text.secondary }}
                                  />
                                </Grid>
                                <Grid item xs>
                                  {parts.map((part, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                        color: theme.palette.text.secondary,
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {
                                      option.structured_formatting
                                        ?.secondary_text
                                    }
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </li>
                      );
                    }}
                  />
                  <Button
                    className={classes.find_store_btn}
                    onClick={() => {
                      if (coordinates) {
                        handleLocationChange({
                          latitude: coordinates.lat,
                          longitude: coordinates.lng,
                          address: searchTerm,
                        });
                      }
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Box>
              ) : (
                <Subheader onLocationChange={handleLocationChange} />
              )}
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Herosection;
