import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import useStyles from './styles';

const GradientFooter = ({ heading, description, button1Text, button2Text, onButton1Click, onButton2Click }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <Box className={classes.textContainer}>
          <Typography variant="h4" className={classes.heading}>
            {heading}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
        </Box>
        <Box className={classes.buttonContainer}>
          <Button variant="contained" className={classes.button} onClick={onButton1Click}>
            {button1Text}
          </Button>
          <Button variant="contained" className={classes.button} onClick={onButton2Click}>
            {button2Text}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GradientFooter;
