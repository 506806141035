import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  text: {
    fontWeight: 'normal',
    textAlign: 'center',
    color: 'grey',
  },
  button: {
    width: '100%',
    maxWidth: 300,
    height: 40,
    borderColor: 'grey',
    color: 'grey',
    '&:hover': {
      borderColor: 'grey',
      backgroundColor: 'rgba(66, 133, 244, 0.04)',
    },
  },
  logo: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(1),
  },
  buttonText: {
    flexGrow: 1,
  },
}));

export default useStyles;