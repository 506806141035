import React from "react";
import useStyles from "./styles";

const MiddleContent = ({
  textSideImage,
  heading,
  description,
  mainImage,
  reverseMiddleContent,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.container}
      style={{ flexDirection: reverseMiddleContent ? "row-reverse" : "row" }}
    >
      <div className={classes.textSide}>
        {textSideImage && (
          <img
            src={textSideImage}
            alt="data.jpg"
            className={classes.textImage}
          />
        )}
        <h2 className={classes.heading}>{heading}</h2>
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className={classes.imageSide}>
        <img
          src={mainImage}
          alt="data.jpg"
          className={classes.roundedImage}
        />
      </div>
    </div>
  );
};

export default MiddleContent;
