import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import AskQuestion from '../HomeScreen/AskQuestion/AskQuestion';
import useStyle from './styles'; // Import or define styles if needed

const ReusableFAQs = ({ frequentlyAskQuestions }) => {
  const classes = useStyle(); // Use styles if needed

  return (
    <Box sx={{ mb: 8 }}>
      <Container>
        <Box>
          <Typography variant='h4' className={classes.accordionTitle}>
            Frequently asked questions
          </Typography>
        </Box>
        {frequentlyAskQuestions.map((questionAns, i) => (
          <AskQuestion 
            key={i}
            title={questionAns.title} 
            description={questionAns.description} 
            dangerouslySetInnerHTML={true} 
          />
        ))}
      </Container>
    </Box>
  );
};

export default ReusableFAQs;
