import React from "react";
import { Box } from "@mui/material";
import useStyles from "./styles";

const TwoFormsComponent = ({
  leftFormComponent,
  rightFormComponent,
  leftLoading,
  rightLoading,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.formContainer}>
        {React.cloneElement(leftFormComponent, { loading: leftLoading })}
      </Box>
      <Box className={classes.formContainer}>
        {React.cloneElement(rightFormComponent, { loading: rightLoading })}
      </Box>
    </Box>
  );
};

export default TwoFormsComponent;
