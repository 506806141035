// import { useState } from "react";
import { Box, Button, Container, Typography, Grid } from "@mui/material";
import useStyle from "./style";
// import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import SearchIcon from '@mui/icons-material/Search';
// import AddCardIcon from '@mui/icons-material/AddCard';
// import CelebrationIcon from '@mui/icons-material/Celebration';
// import WineBarIcon from '@mui/icons-material/WineBar';
import searchicon from "../../../assets/icons/search-icon.svg";
import cardicon from "../../../assets/icons/card-icon.svg";
import carticon from "../../../assets/icons/cart-icon.svg";
import locationicon from "../../../assets/icons/location-icon.svg";
import { useNavigate } from "react-router-dom";

export default function Getstart( {selectedCity} ) {
  //const { t } = useTranslation();
  const classes = useStyle();
  //const theme = useTheme();

  //   const [value, setValue] = useState(0);

  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/store-list", { state: { city: selectedCity} });
  };

  return (
    <>
      <Box className={classes.view_all_stores}>
        <Container>
          <Box className={classes.getstart_section}>
            <Box className={classes.getstart_title}>
              <Typography variant="h2">Bring your home to life</Typography>
              <Button onClick={handleNavigate}>
                View all stores <KeyboardArrowRightIcon />
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container>
        <Box className={classes.step_title}>
          <Typography>How It Works</Typography>
          <Typography variant="h2">Getting started is simple </Typography>
        </Box>
        <Box className={classes.step_section}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} md={6} lg={3} xl={3}>
              <Box className={classes.first_step}>
                <Box className={classes.search_step}>
                  <img src={searchicon} alt="searchicon" />
                </Box>
                <Typography variant="h6">
                  <span>01.</span> Search
                </Typography>
                <Typography>
                  Effortlessly find nearby stores with our advanced,
                  location-based search filter.
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} md={6} lg={3} xl={3}>
              <Box className={classes.first_step}>
                <Box className={classes.search_step}>
                  <img src={carticon} alt="carticon" />
                </Box>
                <Typography variant="h6">
                  <span>02.</span> Choose
                </Typography>
                <Typography>
                Select the perfect store, add the products you need, and get ready to bring your project to life.
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} md={6} lg={3} xl={3}>
              <Box className={classes.first_step}>
                <Box className={classes.search_step}>
                  <img src={cardicon} alt="cardicon" />
                </Box>
                <Typography variant="h6">
                  <span>03.</span> Pay
                </Typography>
                <Typography>
                Your way. Pay online, cash on delivery, or add it to your store trade account. The choice is yours.
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} md={6} lg={3} xl={3}>
              <Box className={classes.last_step}>
                <Box className={classes.search_step}>
                  <img src={locationicon} alt="locationicon" />
                </Box>
                <Typography variant="h6">
                  <span>04.</span> Relax
                </Typography>
                <Typography>
                Track your delivery in real-time and stay connected effortlessly—right to your doorstep.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
