import React from 'react';
import useStyles from './styles';

const InfoGrid = ({ 
  mainHeading, 
  mainDescription, 
  items 
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h2 className={classes.mainHeading}>{mainHeading}</h2>
        <p className={classes.mainDescription}>{mainDescription}</p>
      </div>
      <div className={classes.grid}>
        {items.map((item, index) => (
          <div key={index} className={classes.gridItem}>
            <div className={classes.imageContainer}>
              <img src={item.image} alt={`itempick ${index + 1}`} className={classes.image} />
            </div>
            <h3 className={classes.itemHeading}>{item.heading}</h3>
            <p className={classes.itemDescription}>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoGrid;