import React from "react";
import HeroHeader from "../../components/HeroHeader/HeroHeader";
import HeadingImg from "../../assets/images/BecomeADriveh.svg";
import Footer from "../../components/HomeScreen/Footer";
import CustomCard from "../../components/CustomCard/CustomCard";
import CheckMark from "../../assets/images/CheckMark.png"
import { LoginHeader } from "../../components/Header";

import { useNavigate } from 'react-router-dom';

const DriverEmailConfirmation = () => {
  
  const navigate = useNavigate();  // Initialize useNavigate

  const handleButtonClick = () => {
    // Navigate to the home page
    navigate('/');
  };

  return (
    <>
      <LoginHeader showIcon/>
      <HeroHeader headingText={"Approved"} imageSrc={HeadingImg} />
      <CustomCard
      imageSrc={CheckMark}
      headingText="Welcome to Hedge"
      descriptionText="We are thrilled to have you join us as we aim to make delivery better for everyone. As a delivery/pickup partner, you get to work according to your schedule anywhere, anytime. Scan the QR code below to download the Hedge Driver App and follow the instructions. Need Help? send us an email at driversupport@hedgego.com.au"
      buttonText="Get Started"
      onButtonClick={handleButtonClick}
    />
      <Footer />
    </>
  );
};

export default DriverEmailConfirmation;
