import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import { IoIosArrowDown } from "react-icons/io";
import useStyle from '../../../screens/Delivery/style';

import ready_car from '../../../assets/images/ready_car.png';
import googleplaybtn from '../../../assets/images/google-play.svg';
import appstorebtn from '../../../assets/images/app-store.svg';


const PlaystoreSection = () => {

    const classes = useStyle();

    return (
        <Box className={classes.gaping}>
            <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                <Box className={classes.readyCard}>
                             <Box className={classes.readyTextBox}>
                                <Typography variant='h3'>Ready when you are...</Typography>
                                <Typography>Download the Hedge Delivery App to
                                    start delivering today.</Typography>
                           </Box>
                           <Box className={classes.readyimageBox}>
                               <Box className={classes.download_btn}>
                                   <Box>
                                       <img src={googleplaybtn} alt="googleplaybtn" />
                                       <Typography><IoIosArrowDown /> Show QR Code </Typography>
                                   </Box>
                                   <Box>
                                       <img src={appstorebtn} alt="appstorebtn" />
                                       <Typography><IoIosArrowDown /> Show QR Code</Typography>
                                   </Box>
                               </Box>
                           </Box>
                       </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                <Box className={classes.readyimage}>
                            <img src={ready_car} alt="ready_car" />
                        </Box>
                </Grid>
            </Grid>
            </Container>
        </Box>
    )
}

export default PlaystoreSection