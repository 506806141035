import { makeStyles } from "@mui/styles";

const useStyle = makeStyles(theme => ({
    accordionText: {
        color: '#787878',
        backgroundColor: '#F8F8F8',
        fontSize: '16px',
        fontWeight: '300',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
        },

    },
    accordion: {
        margin: '10px 0',
        boxShadow:'inherit',
        borderRadius:'10px',
        position:'inherit',
    },
    accordionHeader: {
        [theme.breakpoints.down("sm")]: {
            fontSize: '13px',
        },
        color: '#5C5C5C',
        backgroundColor: '#F8F8F8',
        fontSize: '18px',
        fontWeight: '500',
        borderRadius:'12px'
    },
    accordionTitle: {
        [theme.breakpoints.down("sm")]: {
            fontSize: '20px',
            marginBottom: '20px'
        },
        fontSize: '30px',
        fontWeight: '600',
        color: '#0D0D0D',
        marginBottom: '30px',
        borderRadius:'12px',
    },
}))

export default useStyle;
