import React, { useContext, useEffect, useRef, useState } from "react";

export const LocationContext = React.createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);
  console.log("🚀 ~ LocationProvider ~ location:", location);
  const [searchedCity, setSearchedCity] = useState({
    lat: null,
    lng: null,
    cityName: "",
  });
  const [selectedCity, setSelectedCityData] = useState({
    lat: null,
    lng: null,
    cityName: "",
  });

  const setSelectedCity = (data) => {
    if (!data) return;
    setSelectedCityData(data);
  };

  const onSetLocation = (_location) => {
    console.log("🚀 ~ LocationProvider ~ _location:", _location);

    const modified_location = {
      ..._location,
      deliveryAddress: _location?.deliveryAddress ?? _location?.address,
    };

    delete modified_location.address

    setLocation(modified_location);
  };

  const isInitialRender = useRef(true);
  useEffect(() => {
    const locationStr = localStorage.getItem("location");

    if (locationStr && locationStr !== "undefined") {
      setLocation(JSON.parse(locationStr));
    }
  }, []);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (location) localStorage.setItem("location", JSON.stringify(location));
  }, [location]);

  return (
    <LocationContext.Provider
      value={{
        location,
        setLocation: onSetLocation,
        searchedCity,
        setSearchedCity,
        selectedCity,
        setSelectedCity,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => useContext(LocationContext);
