import React from "react";
import HeroHeader from "../../components/HeroHeader/HeroHeader";
import HeadingImg from "../../assets/images/BecomeADriveh.svg";
import Footer from "../../components/HomeScreen/Footer";
import CustomCard from "../../components/CustomCard/CustomCard";
import CheckMark from "../../assets/images/CheckMark.png"
import { LoginHeader } from "../../components/Header";
import { useNavigate } from 'react-router-dom';


const SellerEmailConfirmation = () => {

  const navigate = useNavigate();  // Initialize useNavigate

  const handleButtonClick = () => {
    // Navigate to the home page
    navigate('/');
  };

  return (
    <>
      <LoginHeader showIcon/>
      <HeroHeader headingText={"Approved"} imageSrc={HeadingImg} />
      <CustomCard
      imageSrc={CheckMark}
      headingText="Welcome to Hedge"
      descriptionText="As a delivery delivery/pickup partner you'll get access to all the digital tools to help your business thrive online and off. Need Help? send us an email at vendorsupport@hedgego.com.au"
      buttonText="Get Started"
      onButtonClick={handleButtonClick}
    />
      <Footer />
    </>
  );
};

export default SellerEmailConfirmation;
