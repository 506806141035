import React, { useContext } from "react";
import { Box, Radio, FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";
import { LoginHeader, Header } from "../../components/Header";
import Footer from "../../components/HomeScreen/Footer";
import UserContext from "../../context/User";
import useStyles from "./styles";
import HeroHeader from "../../components/HeroHeader/HeroHeader";
import HeadingImg from "../../assets/images/BecomeADriveh.svg";
import PaymentOptions from "../../components/PaymentOptions/PaymentOptions";
import FormCard from "../../components/FormCard/FormCard";
import SingleTextField from "../../components/Textfield/TextField";
import DoubleTextFieldRow from "../../components/DoubleTextField/DoubleTextField";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import BackArrow from "../../assets/images/Back.png";
import HeadingMoneyRow from "../../components/HeadingMoneyRow/HeadingMoneyRow";
import { useLocation } from "react-router-dom";

const PaymentPage = () => {
  const { isLoggedIn } = useContext(UserContext);
  const classes = useStyles();
  const location = useLocation();
  const { planData } = location.state || {};

  // Extract the values from planData
  const planPrice = parseFloat(
    planData?.price[3].value.replace(/[^0-9.-]+/g, "")
  ); // Convert to number
  const tax = 5.99; // Fixed tax value

  // Calculate the total amount
  const totalAmount = (planPrice + tax).toFixed(2);
  const buttonLabel = `Pay $${totalAmount}`;

  return (
    <>
      {isLoggedIn ? <Header /> : <LoginHeader />}
      <HeroHeader headingText={"Payment"} imageSrc={HeadingImg} />
      <div className={classes.container}>
        <div className={classes.leftSide}>
          <FormCard heading="Payment Details">
            <PaymentOptions />
            <SingleTextField
              placeholder="Card Number"
              name="Card Number"
              required
            />
            <SingleTextField
              placeholder="Name on card"
              name="NameOnCard"
              required
            />
            <DoubleTextFieldRow
              placeholder1="Expiry date (MM/YY)"
              name1="expirydate"
              placeholder2="CCV"
              name2="CCV"
              required
            />
            <div style={{ marginTop: "40px" }}>
              <SubmitButton label={buttonLabel} type="submit" />
            </div>
            <p className={classes.privacyText}>
              Your personal data will be used to process your order, support
              your experience throughout this website, and for other purposes
              described in our privacy policy.
            </p>
          </FormCard>
          <Link to="/PricingPage" className={classes.backLink}>
            <img src={BackArrow} alt="Back" className={classes.backArrow} />
            Back to pricing details
          </Link>
        </div>
        {/* <div className={classes.rightSide}>
          <Box className={classes.rightSideBox}>
            <h2 className={classes.rightSideHeading}>Order Summary</h2>
            <div className={classes.orderSummaryRow}>
              <div className={classes.planName}>
                {planData?.name} Plan
              </div>
              <FormControlLabel
                control={<Radio />}
                label="Monthly"
                className={`${classes.radioButton} ${classes.radioLabel}`}
              />
              <FormControlLabel
                control={<Radio />}
                label="Yearly"
                className={`${classes.radioButton} ${classes.radioLabel}`}
              />
              <span className={classes.planPrice}>
                {planData?.price[3].value.split(" ")[0]}
              </span>
            </div>
            <hr className={classes.separator} />
            <div className={classes.newRow}>
              <SingleTextField
                placeholder="Gift or Discount Code"
                name="discountCode"
                className={classes.TextField}
              />
              <button className={classes.applyButton}>Apply</button>
            </div>
            <hr className={classes.separator} />
            <HeadingMoneyRow
              heading="SubTotal"
              money={planData?.price[3].value.split(" ")[0]}
            />
            <HeadingMoneyRow
              heading="Tax"
              money={`$${tax.toFixed(2)}`}
            />
            <hr className={classes.separator} />
            <HeadingMoneyRow
              heading="Total"
              money={`$${totalAmount}`}
            />
          </Box>
        </div> */}
        <div className={classes.rightSide}>
          <Box className={classes.rightSideBox}>
            <h2 className={classes.rightSideHeading}>Order Summary</h2>

            <div className={classes.orderSummaryRow}>
              <div className={classes.planName}>{planData?.name} Plan</div>
              <div className={classes.radioButtonsContainer}>
                <FormControlLabel
                  control={<Radio />}
                  label="Monthly"
                  className={`${classes.radioButton} ${classes.radioLabel}`}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Yearly"
                  className={`${classes.radioButton} ${classes.radioLabel}`}
                />
              </div>
              <div className={classes.planPrice}>
                {planData?.price[3].value.split(" ")[0]}
              </div>
            </div>

            <hr className={classes.separator} />

            <div className={classes.newRow}>
              <div style={{flexGrow: 3}}>
              <SingleTextField
                placeholder="Gift or Discount Code"
                name="discountCode"
                className={classes.textField}
              />
              </div>
              <div style={{flexGrow: 2}}>
              <button className={classes.applyButton}>Apply</button>
              </div>
            </div>

            <hr className={classes.separator} />

            <HeadingMoneyRow
              heading="Subtotal"
              money={planData?.price[3].value.split(" ")[0]}
            />
            <HeadingMoneyRow heading="Tax" money={`$${tax.toFixed(2)}`} />
            <hr className={classes.separator} />
            <HeadingMoneyRow heading="Total" money={`$${totalAmount}`} />
          </Box>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentPage;
