// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdxW2430pryH_rTxmRmwJBmOpyKteFR5U",
  authDomain: "hedge-99c88.firebaseapp.com",
  projectId: "hedge-99c88",
  storageBucket: "hedge-99c88.appspot.com",
  messagingSenderId: "1003319547886",
  appId: "1:1003319547886:web:397fceb4a92016f5abe0ff",
  measurementId: "G-RQ6HC8ZHHC"
};

export const initialize = () => {
  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  return messaging;
};

export const isFirebaseSupported = async () => {
  return await isSupported();
};
