import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '100%',
    maxWidth: '623px',
    borderRadius: '32px',
    backgroundColor: '#ffffff', 
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    margin: '0 auto', 
    zIndex: 1,
    boxSizing: 'border-box',
    padding: '20px',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      maxWidth: '400px',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
      maxWidth: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRadius: '16px',
      padding: '10px',
      marginLeft: '10px'
    },
  },
  description: {
    marginBottom: theme.spacing(2),
    marginLeft: '108px', 
  },
  heading: {
    color: '#000000', // Black color for the heading
    textAlign: 'left', // Align the heading to the left
    marginBottom: '15px', // Add some space below the heading
    marginLeft: '40px',
    fontSize: '24px', // Adjust the font size as needed
    fontWeight: 'bold', // Make the heading bold
  },
  formContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',

    '& > *': {
      width: '100%',
      maxWidth: '400px',
      marginBottom: '10px',
    },
  },
}));

export default useStyles;
