import React, { useState, useCallback, useEffect, useRef } from "react";
import { LoginHeader } from "../../components/Header";
import Footer from "../../components/HomeScreen/Footer";
import BecomeADriver from "../../assets/images/BecomeADriveh.svg";
import HerosectionLoginPages from "../../components/HeroSectionLoginPage/HeroSectionLoginPage";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import PlaceholderComponent from "../../components/Placeholder/Placeholder";
import ContactUsMid from "../../assets/images/ContactUsMid.png";
import FormCard from "../../components/FormCard/FormCard";
import SingleTextField from "../../components/Textfield/TextField";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import TermsAndConditions from "../../components/Terms&Condition/Terms&Condition";
import GoogleLogin from "react-google-login";
import ConfigurableValues from "../../config/constants";
import useRegistration from "../../hooks/useRegistration";
import { isValidEmailAddress } from "../../utils/customFunction";
import GoogleConnectButton from "../../components/GoogleSignIn/GoogleSignIn";
import GoogleSrc from "../../assets/images/GoogleSignIn.png"; // Make sure this path is correct

const Login = () => {
  const formRef = useRef();
  const { GOOGLE_CLIENT_ID } = ConfigurableValues();
  const {
    goolgeSuccess,
    authenticationFailure,
    loading,
    loginButtonSetter,
    loginError,
    mutateLogin,
    setLoginError,
  } = useRegistration();

  const [formLoading, setFormLoading] = useState(false); 
  const [googleLoading, setGoogleLoading] = useState(false);


  const [formData, setFormData] = useState({
    email: "",
    password: "",
    termsAccepted: false,
  });
  const [mainError, setMainError] = useState({});
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");

  const showMessage = useCallback((messageObj) => {
    setMainError(messageObj);
  }, []);

  useEffect(() => {
    if (loginError) {
      showMessage({
        type: "error",
        message: loginError,
      });
    }
  }, [loginError, showMessage]);


  const clearErrors = () => {
    setEmailError("");
    setPassError("");
    setLoginError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearErrors();
    let validate = true;

    const emailValue = formData.email;
    const passValue = formData.password;

    if (!isValidEmailAddress(emailValue)) {
      setEmailError("Invalid Email");
      validate = false;
    }
    if (!passValue) {
      setPassError("Password required");
      validate = false;
    }

    if (validate) {
      setFormLoading(true);
      try {
        const user = {
          email: emailValue,
          password: passValue,
          type: "default",
        };
        mutateLogin(user);
      } catch (error) {
        setLoginError("Something is missing");
      } finally {
        setFormLoading(false);
      }
    } 
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const callGoogle = useCallback(
    (clickAction) => {
      if (!loading) {
        loginButtonSetter("GOOGLE");
        setGoogleLoading(true);
        clickAction();
      }
    },
    [loading, loginButtonSetter]
  );

  const formComponent = (
    <FormCard heading="Enter your details below">
      <form ref={formRef} onSubmit={handleSubmit}>
        <SingleTextField
          placeholder="Email Address"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={Boolean(emailError)}
          helperText={emailError}
          required
        />
        <SingleTextField
          placeholder="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          isPassword={true}
          error={Boolean(passError)}
          helperText={passError}
          required
        />
        <TermsAndConditions
          label="Remember me"
          name="termsAccepted"
          checked={formData.termsAccepted}
          onChange={handleChange}
        />
        <SubmitButton label="Submit" type="submit" loading={formLoading} />
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          render={(renderProps) => (
            <GoogleConnectButton
              text="or connect via"
              onClick={() => callGoogle(renderProps.onClick)}
              loading={googleLoading}
              googleLogoSrc={GoogleSrc}
            />
          )}
          onSuccess={goolgeSuccess}
          onFailure={authenticationFailure}
          cookiePolicy={"single_host_origin"}
        />
      </form>
    </FormCard>
  );

  return (
    <div>
      <LoginHeader showIcon />
      <HerosectionLoginPages
        imageSrc={BecomeADriver}
        headingText="Login"
        descriptionText="Enter your login details to continue."
      />
      <PlaceholderComponent
        imageSrc={ContactUsMid}
        formComponent={formComponent}
        imageText={"Hi there, welcome back"}
      />
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(mainError.type)}
        autoHideDuration={6000}
        onClose={() => setMainError({})}
      >
        <Alert severity={mainError.type} sx={{ width: "100%" }}>
          {mainError.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;