import React from 'react';
import styles from './styles';

const CustomCard = ({ imageSrc, headingText, descriptionText, buttonText, onButtonClick }) => {
  return (
    <div style={styles.cardContainer}>
      <img src={imageSrc} alt="custom card" style={styles.image} />
      <h4 style={styles.heading}>{headingText}</h4>
      <p style={styles.description}>{descriptionText}</p>
      <button style={styles.button} onClick={onButtonClick}>
        {buttonText}
      </button>
    </div>
  );
};

export default CustomCard;
