import React from 'react';
import useStyles from './style';
import { Typography } from '@mui/material';

const FormCard = ({ heading, descriptionText, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.cardContainer}>
      {heading && (
        <div className={classes.heading}>
          {React.isValidElement(heading) ? heading : <h2>{heading}</h2>}
        </div>
      )}
      {descriptionText && (
        <Typography 
          variant="caption" 
          color="text.disabled" 
          className={classes.description}
        >
          {descriptionText}
        </Typography>
      )}
      <div className={classes.formContent}>
        {children}
      </div>
    </div>
  );
};

export default FormCard;