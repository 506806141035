import React from "react";
import { Box, Typography } from "@mui/material";
import useStyle from "./styles";

const Herosection = ({ imageSrc, headingText, descriptionText }) => {
  const classes = useStyle();

  return (
    <Box className={classes.heroSection}>
      <img src={imageSrc} alt="Hero" className={classes.heroImage} />
      <div className={classes.overlay}></div>
      <Box className={classes.textOverlay}>
        <Typography variant="h4" className={classes.heroHeading}>
          {headingText}
        </Typography>
        <Typography variant="body2" className={classes.heroDescription}>
          {descriptionText}
        </Typography>
      </Box>
    </Box>
  );
};

export default Herosection;
