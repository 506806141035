import makeStyles from "@mui/styles/makeStyles";
import image from "../../../assets/images/bring-life.png";
import getstart from "../../../assets/images/arrow-getstart.png";

const useStyle = makeStyles((theme) => ({
  getstart_section: {
    backgroundImage: `url(${image})`,
    width: "100%",
    height: "160px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    marginTop: "75px",
    borderRadius: "10px",

    "&:before": {
      content: '""',
      width: "100%",
      height: "100%",
      background: "#000",
      position: "absolute",
      top: 0,
      left: 0,
      opacity: "40%",
      borderRadius: "10px",
    },
  },
  view_all_stores: {
    width: "100%",
  },
  getstart_title: {
    position: "absolute",
    zIndex: "999",
    minWidth: "-webkit-fill-available",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    padding: "0px 25px",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    "& h2": {
      fontSize: "30px",
      fontWeight: "600",
      color: "#fff",
      [theme.breakpoints.down("sm")]: {
        fontSize: '20px',
    },
    },
    "& button": {
      backgroundColor: "#c0e341 !important",
      color: "#000",
      textTransform: "capitalize",
      padding: "10px 20px",
      fontWeight: "600",
      borderRadius: "5px",
    },
  },
  step_section: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    marginBottom: "70px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      gap: "0px",
    },
  },
  step_title: {
    marginTop: '50px',
    marginBottom: "30px",
    "& h2": {
      fontSize: "30px",
      fontWeight: "600",
      color: "#1e1a18",
      [theme.breakpoints.down("sm")]: {
        fontSize: '20px',
    },
    },
    "& p": {
      fontWeight: "500",
      color: "#787878",
      marginBottom: "10px",
    },
  },
  first_step: {
    position:'relative',
    "&:before":{
      content:'""', 
      backgroundImage: `url(${getstart})`,
      position:'absolute',
      backgroundRepeat:'no-repeat',
      width:'90px',
      height:'18px',
      backgroundSize:'contain',
      right:'40px',
      top:'44px',
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "15px",
    },
    "& h6": {
      color: "#1e1a18",
      marginBottom: "10px",
      fontSize: "18px",
      fontWeight: "500",
    },
    "& p": {
      color: "#666",
      fontSize: "14px",
    },
  },

  last_step:{
    position:'relative',
    [theme.breakpoints.down("md")]: {
      marginBottom: "15px",
    },
    "& h6": {
      color: "#1e1a18",
      marginBottom: "10px",
      fontSize: "18px",
      fontWeight: "500",
    },
    "& span": {
      // color: '#aac810'
    },
    "& p": {
      color: "#666",
      fontSize: "14px",
    },
  },
  search_step: {
    width: "100px",
    height: "100px",
    borderRadius: "100px",
    border: "1px solid #333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      margin: '0 auto'
    },
    "& svg": {
      fontSize: "35px",
    },
  },
}));

export default useStyle;
