import React from "react";
import { Box, Typography } from "@mui/material";
import useStyles from "./styles";

const PlaceholderComponent = ({ imageSrc, formComponent, imageText }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.imageContainer}>
        <img 
          src={imageSrc} 
          alt="Placeholder" 
          className={`${classes.image} ${imageText ? classes.smallerImage : ''}`} 
        />
        {imageText && (
          <Typography className={classes.imageText}>{imageText}</Typography>
        )}
      </Box>
      <Box className={classes.formContainer}>
        {formComponent}
      </Box>
    </Box>
  );
};

export default PlaceholderComponent;