import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#F9F9F9',
    padding: '40px',
    borderRadius: '10px',
    width: '100%',
    margin: '0 auto',
  },
  header: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  mainHeading: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '10px',
  },
  mainDescription: {
    fontSize: '16px',
    color: '#757575',
    // background: '#757575',
    display: 'inline-block',
    padding: '5px 10px',
    borderRadius: '5px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '30px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageContainer: {
    width: '100%',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    borderRadius: '10px',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  },
  itemHeading: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '10px',
  },
  itemDescription: {
    fontSize: '14px',
    color: '#757575',
    textAlign: 'center',
    padding: '20px'
  },
}));

export default useStyles;