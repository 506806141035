import React from 'react';
import { RadioGroup, FormControlLabel, Radio, FormControl } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import useStyles from './styles.js'; // Import the styles from the separate file
import PayByCardImage from '../../assets/images/PayByCardImage.png';
import PaypalImage from '../../assets/images/PaypalImage.png';


const PaymentOptions = () => {
  const classes = useStyles();
  const [paymentMethod, setPaymentMethod] = React.useState('card');

  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  return (
    <div className={classes.container}>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          className={classes.radioGroup}
          value={paymentMethod}
          onChange={handleChange}
        >
          <FormControlLabel
            value="card"
            control={<Radio />}
            label={
              <div className={classes.iconLabel}>
                <img src={PayByCardImage} alt="Pay by Card" className={classes.iconPayByCard} />
                Pay by Card
              </div>
            }
          />
          <FormControlLabel
            value="paypal"
            control={<Radio />}
            label={
              <div className={classes.iconLabel}>
                <img src={PaypalImage} alt="PayPal" className={classes.iconPaypal} />
                PayPal
              </div>
            }
          />
        </RadioGroup>
      </FormControl>
      <LockIcon className={classes.lockIcon} />
    </div>
  );
};

export default PaymentOptions;
