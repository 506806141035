import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import useStyle from '../../../screens/Delivery/style';

const Stepper = (props) => {

    const classes = useStyle();

    return (
        <Box className={classes.steppers}>
            <Container>
                <Box className={classes.howWork_title}>
                    <Typography variant="h2">{props.sectionTitle}</Typography>
                </Box>
                <Box className={classes.howWork_section}>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12} md={6} lg={4} xl={4}>
                            <Box className={classes.howWork_step2}>
                                <Box className={classes.howWork_step}>
                                    <Typography variant='h2'>1</Typography>
                                </Box>
                                <Typography variant="h6">
                                {props.firstTitle}
                                </Typography>
                                <Typography>
                                {props.firstDescription}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={4} xl={4}>
                            <Box className={classes.howWork_step2}>
                                <Box className={classes.howWork_step}>
                                    <Typography variant='h2'>2</Typography>
                                </Box>
                                <Typography variant="h6">
                                {props.secondTitle}
                                </Typography>
                                <Typography>
                                {props.secondDescription}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={4} xl={4}>
                            <Box className={classes.last_step}>
                                <Box className={classes.howWork_step}>
                                    <Typography variant='h2'>3</Typography>
                                </Box>
                                <Typography variant="h6">
                                {props.thirdTitle}
                                </Typography>
                                <Typography>
                                {props.thirdDescription}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default Stepper