import React, { useContext, useEffect } from "react";
import { LoginHeader, Header } from "../../components/Header";
import Footer from "../../components/HomeScreen/Footer";
import UserContext from "../../context/User";
import AboutUsImage from "../../assets/images/AboutUs.png";
import Herosection from "../../components/HeroSection/HeroSection";
import { Typography, Box } from "@mui/material";
import MiddleContent from "../../components/MiddleComponent/MiddleComponent";
import AboutUsDriver from "../../../src/assets/images/AboutUsDriver.png";
import HedgeIcon from "../../../src/assets/images/HedgeIcon.png";
import AboutUsCustomer from "../../../src/assets/images/AboutUsCustomer.png";
import AboutUsVendor from "../../../src/assets/images/AboutUsVendor.png";
import InfoGrid from "../../components/InfoGrid/InfoGrid.";
import AboutUsFirstMobile from "../../../src/assets/images/AboutUsMobileImage1.png";
import AboutUsSecondMobile from "../../../src/assets/images/AboutUsMobileImage2.png";
import AboutUsThirdMobile from "../../../src/assets/images/AboutUsMobileImage3.png";
import GradientFooter from "../../components/GradientFooter/GradientFooter";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const { isLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleButton1Click = () => {
    navigate('/business-partner')
  };

  const handleButton2Click = () => {
    navigate('/delivery')
  };

  const data = {
    mainHeading: "Why Hedge?",
    mainDescription: "Because ordering and delivering home improvement and landscaping supplies should be simple",
    items: [
      {
        image: AboutUsFirstMobile,
        heading: "Find a store",
        description: "Enter your postcode on our home page to begin. Explore our extensive list of suppliers who deliver to your area. Choose a supplier and start browsing their wide range of products.",
      },
      {
        image: AboutUsSecondMobile,
        heading: "Place your order",
        description: "Transform your indoor and outdoor spaces with ease. Simply select from local suppliers, explore their menu, and choose the items you wish to order.",
      },
      {
        image: AboutUsThirdMobile,
        heading: "Delivery",
        description: "Sit back and relax as we keep you updated every step of the way. Expect push notifications via our mobile app confirming your order details and delivery time. Then, simply unwind and await your order, delivered right to your doorstep.",
      },
    ],
  };

  return (
    <>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      {/* <Card
        sx={{
          position: "absolute",
          right: "10%",
          top: "23rem",
          transform: "translateY(-50%)",
          width: "430px",
          borderRadius: "8px",
          zIndex: 10,
          display: {
            xs: "none", // Hide on extra-small screens
            sm: "none", // Hide on small screens
            md: "block", // Show on medium and larger screens
          },
        }}
      >
        <CardContent>
          <Typography
            variant="body2"
            sx={{
              color: "#808080",
              fontSize: "0.775rem",
              "& p": {
                marginBottom: "0.3em",
              },
            }}
          >
            <h2 style={{ color: "black" }}>Who is Hedge</h2>
            <br />
            <p>
              Hedge is a comprehensive online home improvement delivery platform
              designed to seamlessly connect suppliers with customers, catering
              to all their home improvement needs.
            </p>
            <br />
            <p>Founded by: Daniel Gobbo</p>
          </Typography>
        </CardContent>
      </Card> */}
      <Herosection
        imageSrc={AboutUsImage}
        headingText="About Us"
        descriptionText="All things home improvement"
      />
      <Box sx={{ textAlign: "center", marginTop: "70px" }}>
        <Typography
          variant="h5"
          sx={{ color: "black", fontWeight: "bold", marginBottom: "10px" }}
        >
          Bringing people closer together
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "grey", fontSize: "1rem", marginBottom: "40px" }}
        >
          Hedge supports local businesses, creates local jobs, helps local
          communities to thrive.
        </Typography>
      </Box>
      <MiddleContent
        textSideImage={HedgeIcon}
        heading="#Vendors"
        description="At Hedge, we believe in bringing people together.Our mission is to empower and foster the growth of local businesses.<br/><br/>
                     When customers place orders through our platform local businesses flourish, and anyone with a vehicle can earn a sustainable income through delivery opportunities."
        mainImage={AboutUsVendor}
        reverseMiddleContent={false}
      />
      <MiddleContent
        textSideImage={HedgeIcon}
        heading="#Drivers"
        description="Anyone, anywhere, at any time has the opportunity to earn an income delivering for Hedge. Whether you prefer to work part-time or full-time, you have the flexibility to choose your hours and your preferred locations.<br/> <br/> It's up to you."
        mainImage={AboutUsDriver}
        reverseMiddleContent={true}
      />
      <MiddleContent
        textSideImage={HedgeIcon}
        heading="#Customers"
        description="Discover all your home improvement and landscaping supplies conveniently gathered together in one place.<br/><br/> Now, with just the touch of a button, you can order a wide of items such as plants, pavers, soil, mulch, turf, garden edging, timber, decking, outdoor furniture, BBQs, nails, fixings and much more from trusted suppliers."
        mainImage={AboutUsCustomer}
        reverseMiddleContent={false}
      />
      <InfoGrid
        mainHeading={data.mainHeading}
        mainDescription={data.mainDescription}
        items={data.items}
      />
       <GradientFooter
        heading="Sign up and get started."
        description="We’re here to help you take control of your online orders and upgrade your customers to a better delivery experience."
        button1Text="Become a Vendor"
        button2Text="Become a Driver"
        onButton1Click={handleButton1Click}
        onButton2Click={handleButton2Click}
      />
      <Footer />
    </>
  );
};

export default AboutUs;
