// src/components/CommissionSection/style.js

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    commissionDetails: {
        background: 'linear-gradient(42.09deg, #B9DE31 22.23%, #C8EA19 77.77%)',
        padding: '100px 15px 71px 15px',
        borderRadius: '14px',
        textAlign: 'center',
        [theme.breakpoints.down("md")]: {
            padding: '70px 15px 51px 15px',
        },
        [theme.breakpoints.down("sm")]: {
            padding: '30px 15px 30px 15px',
        },
    },
    commissionHeading: {
        fontSize: '24px',
        fontWeight: '600',
        color: theme.palette.common.color,
        marginBottom: '8px',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
        },
    },
    commissionContant: {
        fontSize: '20px',
        fontWeight: '300',
        color: theme.palette.common.color,
        marginBottom: '0px',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
        },
    },
}));

export default useStyles;
