import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "80px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "0px",
    },
  },
  formContainer: {
    flex: 1,
    margin: "0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 20px 0", // Adjust spacing for small screens
    },
  },
  imageText: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
    fontWeight: '500',
  },
}));

export default useStyles;
