import React from 'react';
import { Box, Typography } from '@mui/material';
import tickIcon from "../../assets/images/singlepoint.svg"
function CustomPoint({ text }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={tickIcon}
        alt="point-icon"
        style={{ width: '16px', height: '16px', marginRight: '10px' }}
      />
      <Typography variant="body1" sx={{ color: '#808080', fontFamily: 'Be Vietnam Pro', fontSize: '13px' }}>
        {text}
      </Typography>
    </Box>
  );
}

export default CustomPoint;
