// src/components/BusinessPartner/CommissionSection.js

import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import useStyle from './styles'; // Ensure this path is correct

const CommissionSection = () => {
  const classes = useStyle();

  return (
    <Box className={classes.commissionSection}>
      <Container>
        <Box className={classes.commissionDetails}>
          <Typography variant="h3" className={classes.commissionHeading}>
            Get 3-months of Hedge commission free when you become a stockist.
          </Typography>
          <Typography className={classes.commissionContant}>
            It’s our way of supporting your business growth and partnership with us.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default CommissionSection;
