import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { Header, LoginHeader } from "../../components/Header";
import Footer from "../../components/HomeScreen/Footer";
import UserContext from "../../context/User";
import useStyle from "./style";
import business from "../../../src/assets/images/business.png";
import deliveryImage from "../../../src/assets/images/deliveryImage.png";
import hedge from "../../../src/assets/images/hedge.png";
import check_mark from "../../../src/assets/images/check_mark.svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import rectangle from "../../../src/assets/images/rectangle.png";
import partners1 from "../../../src/assets/images/partners1.png";
import partners2 from "../../../src/assets/images/partners2.png";
import partners3 from "../../../src/assets/images/partners3.png";
import partners4 from "../../../src/assets/images/partners4.png";
import partners5 from "../../../src/assets/images/partners5.png";
import sellingImage from "../../../src/assets/images/sellingImage.png";
import ap from "../../../src/assets/images/ap.png";
import { IoLocationOutline } from "react-icons/io5";
import { IoCarSportOutline } from "react-icons/io5";
import Stepper from "../../components/HomeScreen/Delivery/Stepper";
import SolutionNeeds from "../../components/HomeScreen/SolutionNeeds/SolutionNeeds";
import customer_retention from "../../../src/assets/icons/customer-retention.svg";
import revenue from "../../../src/assets/icons/revenue.svg";
import evolve from "../../../src/assets/icons/evolve.svg";
import { useNavigate } from "react-router-dom";
import stairs from "../../assets/images/Stairs.png";
import ReusableFAQs from "../../components/ReUsableFAQs/ReUsableFAQs";

const frequentlyAskQuestions = [
  {
    title: "How do I get started?",
    description: `<p>Simple! </p><br/>
<p>Click on Become a vendor tab in the footer and complete the online form, we’ll send 
you a confirmation email when we have reviewed your details.  </p><br/>
<p>If you're an existing Hedge stockist, just send us an email at stores@hedge.net.au 
and we'll swiftly set up your online store for you, completely free of charge for 90- 
days.</p> `,
  },
  {
    title: "Why should I partner with Hedge?",
    description: ` <p>It’s easy!</p><br/>
    <p>As a partner with Hedge, you benefit from:</p><br/>
    <p><strong>Online Store:</strong> Your online store can accept and process orders 24/7 through the Hedge app.</p><br/>
    <p><strong>Flexible Delivery Options:</strong> Provide Delivery, Pick-up or Drive thru options with ease.</p><br/>
    <p><strong>Real-time Tracking:</strong> Customers can track their orders, while drivers optimise the fastest routes for delivery.</p><br/>
    <p><strong>Instant Messaging:</strong> Seamless communication between customers, stores, and drivers.</p><br/>
    <p><strong>Business App:</strong> Manage your business on the go with the Hedge Business App. Track order, deliveries, and manage products and inventory effortlessly.</p><br/>
    <p><strong>Automated Orders:</strong> Forget the phone orders; receive printed orders via GPRS printer for efficiency.</p><br/>
    <p><strong>Business Insights:</strong> Gain valuable insights into your business with intuitive analytics. Understand delivery zones, peak delivery times, top-selling products and more.</p>`,
  },
  {
    title: "How do I charge for deliveries?",
    description: ` <p>Gain better control over your deliver fees and charges. <p/><br/>
  <p>Set your delivery zone and add a km rate you charge outside of the delivery zone which you serve. <p/><br/>
  <p>Example: Your local deliveries are charged at $65.00 within a 20km radius + for every km there after you charge $3.80 per km.</p><br/>
  <p>The Hedge Delivery App is meticulously crafted to elevate your customers delivery experience, ensuring they enjoy nothing short of excellence with each order.</p><br/>
  <p>*Please note: Even if you offer FREE DELIVERY, Hedge will still apply a minimum commission fee of $5.50 per delivery. It's essential to bear this in mind when implementing free delivery to your customers.</p>`,
  },
  {
    title: "How does Hedge work?",
    description: `<p> You load the trucks, while we take care of the tech.</p><br/>
    <p> "Think of us as the software that connects your customers to a better shopping experience".</p><br/>
    <p>Customers search your store's menu, select the products they need, and make payment via the Hedge marketplace app.</p><br/>
    <p>Your store receives customer orders via email and the Hedge Business App.</p><br/>
    <p>Your delivery driver receives the notification, loads the products, and delivers them to the customer.</p><br/>
    It's really that simple.`,
  },
  {
    title: "How do I receive orders and get paid?",
    description: `<p> Receiving customer orders and getting paid is simple.</p><br/>
    <p>Let's start with how you receive customer orders.</p><br/>
    <p><strong>Email:</strong> Customer orders come directly to your email address provided when you set up your online store.</p><br/>
    <p><strong>Smartphone:</strong> Download the Hedge Business App to view, manage, and track customer orders all in one place.</p><br/>
    <p><strong>Online:</strong> Login into your hedge account. That way, you'll never miss an order again.</p><br/>
    <p>The important part "How we pay you".</p><br/>
    <p><strong>Direct to your bank account:</strong> From your account go to your Hedge wallet and submit a payout request. Hedge remits payment to your nominated bank account every 7 days, deducting any platform fees or commissions according to the plan that you selected (DIY, DIFM or ENTERPRISE).</p>`,
  },
  {
    title: "How does Hedge work for my trade account customers?",
    description: `<p> Ever thought there must be an easier way to help your trade customers do more in less time? </p><br/>
<p>Your trade account customers will love being able to order line, check out instore and track their deliveries in real-time without leaving the jobsite or calling to see where Dave is with the pine bark fines.</p><br/>
<p>With real-time delivery tracking and centralised management of orders, receipts, invoices, delivery dockets all in one place.</p><br/> 
Get your trade customer to simply download the hedge app today, for better deliveries tomorrow. `,
  },
  {
    title: "How do I manage Returns & Refunds?",
    description: ` <p>Hedge operates as an online marketplace compromising of trusted local stores, suppliers and brands, each with their own refund and return policy.</p><br/>
<p>If you need to request a refund or return your products, simply contact the supplier directly.</p><br/>
<p>*Please note that delivery, return, or restocking fees may apply.</p>
Additionally, it's important to be aware that certain items may not be eligible for 
refunds due to their nature and delivery method. For instance, bulk products such as 
turf, plants, pavers, soil and mulch are typically non-refundable.`,
  },
];

const BusinessPartner = () => {
  const classes = useStyle();

  const navigate = useNavigate();
  const { isLoggedIn } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}

      <Box className={classes.heroSection}>
        <Container>
          <Box className={classes.heroTextBanner}>
            <Typography variant="h2">Partner with us</Typography>
            <Typography>You load the trucks, we'll handle the rest</Typography>
          </Box>
        </Container>
      </Box>

      {/* Business Solution */}
      <Box className={classes.businessBox}>
        <Container>
          <Grid container spacing={2} margin={0}>
            <Grid sm={12} md={6} lg={4}>
              <Box className={classes.businessImage}>
                <img src={business} alt="business" />
              </Box>
            </Grid>
            <Grid sm={12} md={6} lg={8}>
              <Box className={classes.businessTextBox}>
                <Box className={classes.businessTitle}>
                  <Typography variant="h3">
                    Effortless. Secure. Scalable.
                  </Typography>
                  <Typography>
                    Looking to captivate new customers or offer your loyal
                    shoppers a seamless experience? We’re here to make it
                    happen.
                  </Typography>
                  <Typography>
                    It’s simple: We’ll bring your store and products online,
                    streamline the order process, and ensure timely deliveries
                    to more happy customers in more neighborhoods—all in
                    real-time.
                  </Typography>
                  <Typography>Ready to grow?</Typography>
                  <Typography>Let's start our partnership today!</Typography>
                </Box>
                <Box className={classes.businessBtn}>
                  <Button>Get Started</Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Stepper
        sectionTitle="It's simple"
        firstTitle="Order"
        firstDescription="The customer places an order through the Hedge App."
        secondTitle="Prepare"
        secondDescription="Your store will receive a notification to start preparing the order."
        thirdTitle="Deliver"
        thirdDescription="Your driver accepts the order and delivers it to the customer."
      />

      {/* Grow your business with Hedge */}
      <Box
        sx={{ marginBottom: "75px" }}
        className={classes.growBusinessSection}
      >
        <Container>
          <Grid
            container
            spacing={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item sm={12} md={6} lg={8}>
              <Box className={classes.growBusiness}>
                <Box className={classes.growBusinessTitle}>
                  <Typography variant="h3">
                    Grow your business with Hedge
                  </Typography>
                  <Typography>
                    When you join, you'll have everything you need to get
                    started.
                  </Typography>
                </Box>
                <Box>
                  <List className={classes.growList}>
                    <ListItem>
                      <img src={check_mark} alt="check_mark" />
                      <Typography>Your company-branded online store</Typography>
                    </ListItem>
                    <ListItem>
                      <img src={check_mark} alt="check_mark" />
                      <Typography>
                        Seamless Delivery and Pick up software solution
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <img src={check_mark} alt="check_mark" />
                      <Typography>
                        Run and manage your business on the go with the hedge
                        business app
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <img src={check_mark} alt="check_mark" />
                      <Typography>
                        In-store signage and marketing material
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <img src={check_mark} alt="check_mark" />
                      <Typography>
                        Advanced loyalty, marketing and promotional tools
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <img src={check_mark} alt="check_mark" />
                      <Typography>Social media integration</Typography>
                    </ListItem>
                    <ListItem>
                      <img src={check_mark} alt="check_mark" />
                      <Typography>
                        24/7 online shopping and schedule deliveries up to 14
                        days in advance
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box className={classes.restaurant_Box}>
                <Box className={classes.restaurant_img_box}>
                  <img src={stairs} alt="dummyNeighbourhood" />
                </Box>
                <Box className={classes.profile_image}>
                  <img src={hedge} alt="hedge_logo" />
                </Box>
                <Box className={classes.restaurant_card_content_box}>
                  <Box className={[classes.restaurant_card_box]}>
                    <Typography
                      variant="h3"
                      sx={{ marginBottom: "0px !important" }}
                    >
                      Hedge Store
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      marginLeft="10px"
                    >
                      <Rating
                        name="rating"
                        defaultValue={1}
                        max={1}
                        sx={{ fontSize: "18px" }}
                        readOnly
                      />
                      <Typography sx={{ marginLeft: "5px" }}>5(126)</Typography>
                    </Stack>
                  </Box>
                  <Box className={classes.flexRow}>
                    <Typography variant="h6">Landscape Supplies</Typography>
                    <Stack direction="row">
                      <IoLocationOutline size={19} color="#5b606e" />
                      <Typography>Chatswood</Typography>
                    </Stack>
                  </Box>
                </Box>
                <Box className={classes.restaurant_card_footer}>
                  <Box className={classes.delivery_time}>
                    <AccessTimeIcon />
                    <Typography>30 mins</Typography>
                  </Box>
                  <Box className={classes.delivery_time}>
                    <IoCarSportOutline />
                    <Typography>$35</Typography>
                  </Box>
                  <Box className={classes.delivery_time}>
                    <Typography>Min Order $ 30.00</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Hedge brings new opportunities */}
      <SolutionNeeds
        sectionTitle="Hedge brings new opportunities"
        firstTitle="Attract new customers"
        firstDescription="Adding your business to Hedge means accessing new customers in more neighbourhoods."
        secondTitle="Increase your revenue"
        secondDescription="Let customers enjoy your business from anywhere, and capture the interest of new ones who haven’t heard of you yet."
        thirdTitle="Grow your business"
        thirdDescription="We take care of all the digital stuff. Leaving you to focus on what matters."
        solutions_home={customer_retention}
        solutions_boy={revenue}
        solutions_woman={evolve}
      />

      {/* Delivery Solution */}
      <Box className={classes.deliverySection}>
        <Container>
          <Box className={classes.delivery}>
            <Box className={classes.deliveryImage}>
              <img src={deliveryImage} alt="deliveryImage" />
            </Box>
            <Box className={classes.deliveryDetails}>
              <Typography variant="h3" className={classes.deliveryHeading}>
                The #1 delivery solution for home improvement.
              </Typography>
              <Typography className={classes.deliveryContant}>
                Unlock a suite of powerful tools designed to help you promote,
                sell, manage, and automate the distribution of your
                products—online and offline.
              </Typography>
              <Typography className={classes.deliveryContant}>
                Hedge serves all your home improvement needs, catering to
                landscaping supply yards, garden centers, hardware stores, turf
                suppliers, nurseries, outdoor furniture specialists, paving and
                tile suppliers, irrigation suppliers, and more. From indoors to
                outdoors, we’ve got you covered.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* How to start selling on Hedge */}
      <Box className={classes.startSimple}>
        <Container>
          <Box className={classes.startSimpleTitle}>
            <Typography variant="h2">How to start selling on Hedge</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6} lg={6} className={classes.stepBox}>
              <Box className={classes.stepContainer}>
                <Box className={classes.stepperBox}>
                  <Box className={classes.stepperImage}>
                    <img src={rectangle} alt="rectangle" />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Sign up</Typography>
                    <Typography>
                      Partner with Hedge and tell us about your business
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.stepperBox}>
                  <Box className={classes.stepperImage}>
                    <img src={rectangle} alt="rectangle" />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Set up</Typography>
                    <Typography>
                      List your store, add your products, set delivery zones and
                      fees
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.stepperBox}>
                  <Box className={classes.hedgeLogo}>
                    <img src={ap} alt="ap" className={classes.hLogo} />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Sell</Typography>
                    <Typography>
                      Track and manage your orders across any device
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className={classes.stepperBtn}
                  sx={{ textAlign: "start !important" }}
                >
                  <Button>Sign Up</Button>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <Box>
                <img
                  src={sellingImage}
                  alt="sellingImage"
                  className={classes.sellingImage}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our trusted partners */}
      <Box className={classes.partnersSection}>
        <Container>
          <Box>
            <Typography variant="h2" className={classes.partnersHeading}>
              Our trusted partners big and small
            </Typography>
          </Box>
          <Box className={classes.partnersImage}>
            <img src={partners1} alt="partners1" />
            <img src={partners2} alt="partners2" />
            <img src={partners3} alt="partners3" />
            <img src={partners4} alt="partners4" />
            <img src={partners5} alt="partners5" />
          </Box>
        </Container>
      </Box>

      {/* Commission Free */}
      <Box className={classes.commissionSection}>
        <Container>
          <Box className={classes.commissionDetails}>
            <Typography variant="h3" className={classes.commissionHeading}>
              Get 3-months of Hedge commission free when you become a stockist.
            </Typography>
            <Typography className={classes.commissionContant}>
              It’s our way of supporting your business growth and partnership
              with us.
            </Typography>
          </Box>
        </Container>
      </Box>
      {/* Delivery Journey */}
      <Box className={classes.deliveryJourneySection}>
        <Container>
          <Box>
            <Typography variant="h2" className={classes.partnersHeading}>
              Your online delivery journey starts here
            </Typography>
            <Typography className={classes.partnersContant}>
              Easy to start, Easy to use, FREE to join
            </Typography>
          </Box>
          <Box className={classes.planButton}>
            <Box>
              <Button
                className={classes.ChoosePlan}
                onClick={() => {
                  navigate("/PricingPage");
                }}
              >
                Choose Your Plan
              </Button>
            </Box>
            <Box>
              <Button className={classes.ChoosePlan}>Become A Stockist</Button>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Frequently asked questions */}
      <Box sx={{ mb: 8 }}>
        {/* <Container>
          <Box>
            <Typography variant="h4" className={classes.accordionTitle}>
              Frequently asked questions
            </Typography>
          </Box>
          {frequentlyAskQuestions.map((questionAns, i) => (
            <AskQuestion
              title={questionAns.title}
              description={questionAns.description}
            />
          ))}
        </Container> */}
        <ReusableFAQs frequentlyAskQuestions={frequentlyAskQuestions} />
      </Box>

      <Footer />
    </Box>
  );
};

export default BusinessPartner;
