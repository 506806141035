import React from "react";
import { Box, Typography } from "@mui/material";
import useStyle from "./styles";

const HeroHeader = ({ imageSrc, headingText }) => {
  const classes = useStyle();

  return (
    <Box className={classes.heroHeader}>
      <img src={imageSrc} alt="Hero" className={classes.heroImage} />
      <div className={classes.overlay}></div>
      <Box className={classes.centeredText}>
        <Typography className={classes.heroHeading}>
          {headingText}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroHeader;
