import React from 'react';

const AllStores = () => {
    return (
       <>
       </>
    );
}


export default AllStores;
