import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import useStyle from '../../../screens/Delivery/style';

const HeroSection = () => {

    const classes = useStyle();

    return (
        <Box className={classes.heroSection}>
            <Container>
                <Box className={classes.heroTextBanner}>
                    <Typography variant='h2'>Change the way you deliver.</Typography>
                    <Typography  sx={{color: '#fffff' ,fontSize: '24px'}}>For those who expect more - There’s Hedge</Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default HeroSection