import React from "react";
import HeroHeader from "../../components/HeroHeader/HeroHeader";
import HeadingImg from "../../assets/images/BecomeADriveh.svg";
import Footer from "../../components/HomeScreen/Footer";
import CustomCard from "../../components/CustomCard/CustomCard";
import CheckMark from "../../assets/images/CheckMark.png"
import { LoginHeader } from "../../components/Header";
import { useNavigate } from 'react-router-dom';


const ThankYou = () => {

  const navigate = useNavigate();  // Initialize useNavigate

  const handleButtonClick = () => {
    // Navigate to the home page
    navigate('/');
  };

  return (
    <>
      <LoginHeader showIcon/>
      <HeroHeader headingText={"Thank you"} imageSrc={HeadingImg} />
      <CustomCard
      imageSrc={CheckMark}
      headingText="Your subscription has been activated"
      descriptionText="A member of our team will reach out to you shortly to help you get set-up and answer any questions you might have. Need Help? send us an email at vendorsupport@hedgego.com.au"
      buttonText="Go to Dashboard"
      onButtonClick={handleButtonClick}
    />
      <Footer />
    </>
  );
};

export default ThankYou;

