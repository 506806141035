// src/components/HeadingMoneyRow/HeadingMoneyRow.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles'; // Import styles if needed

const HeadingMoneyRow = ({ heading, money }) => {
  const classes = useStyles(); // Use styles if needed

  return (
    <Box className={classes.headingMoneyRow}>
      <Typography variant="h6" className={classes.heading}>
        {heading}
      </Typography>
      <Typography variant="h6" className={classes.money}>
        {money}
      </Typography>
    </Box>
  );
};

export default HeadingMoneyRow;
