import React from "react";
import { Box } from "@mui/material";
import SectionHeading from "../HeadingComponent/HeadingComponent";
import SectionDescription from "../DescriptionComponent/DescriptionComponent";
import PointsSection from "../PointSection/PointSection";
import useStyles from "./styles";

const LeftCard = () => {
  const classes = useStyles();

  return (
    <Box className={classes.sectionContainer}>
      <Box sx={{ minHeight: "230px", borderBottom: "1px solid lightgrey", maxHeight: 'fit-content'}}>
        <SectionHeading text="Marketplace" />
        <SectionDescription text="Attract new customers and increase sales when you list your business on Hedge." />
        <SectionDescription text="*For retailers and suppliers big and small" />
        <PointsSection
          points={[
            "Market to more customers and better connect with existing ones",
            "Our DIY plan is free to join, you only pay when you sell something",
            "Become a Hedge stockist and get 3-months commission-free on us",
            "Credit card processing fee 2.9% + $0.30c on all transactions",
          ]}
        />
      </Box>
      {/* 
      <Divider sx={{ margin: "20px 0" }} /> */}

      <Box sx={{ height: "220px", borderBottom: "1px solid lightgrey " }}>
        <SectionHeading text="Delivery" />
        <SectionDescription text="Satisfy more customers with a live tracking order experience delivered by your drivers." />
        <PointsSection
          points={[
            "Connect your drivers to the Hedge Driver App",
            "Set your delivery fees, manage delivery zones and keep 100% of customer tips",
            "Advanced logistics delivery software",
          ]}
        />
      </Box>
      {/* <Divider sx={{ margin: "20px 0" }} /> */}

      <Box sx={{ minHeight: "220px", borderBottom: "1px solid lightgrey", maxHeight: 'fit-content' }}>
        <SectionHeading text="Shopfront" />
        <SectionDescription text="Grow your business and generate orders directly from your website." />
        <PointsSection
          points={[
            "No monthly fees ",
            "Increase your revenue",
            "24/7 online store, you'll never miss an order again ",
          ]}
        />
      </Box>
      {/* <Divider sx={{ margin: "20px 0" }} /> */}

      <Box sx={{ height: "220px", borderBottom: "1px solid lightgrey " }}>
        <SectionHeading text="Subscription" />
        <SectionDescription text="You’ve got a business to run and customers to serve. Take the hassle out of setting up your online store and get Hedge to build it for you." />
        <PointsSection
          points={[
            "Save time and money",
            "Includes online store, product uploads, delivery zone and fee setup",
            "Don't have a website? Sign up for a Hedge plan today!",
          ]}
        />
      </Box>
    </Box>
  );
};

export default LeftCard;
