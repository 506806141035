// import { Opacity } from "@mui/icons-material";
// import { colors } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  getstart_section: {
    backgroundImage:
      "url(https://images.unsplash.com/photo-1568992687947-868a62a9f521?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
    width: "100%",
    height: "160px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    margin: "50px 0px",
    borderRadius: "10px",

    "&:before": {
      content: '""',
      width: "100%",
      height: "100%",
      background: "#000",
      position: "absolute",
      top: 0,
      left: 0,
      opacity: "40%",
      borderRadius: "10px",
    },
  },
  view_all_stores: {
    width: "100%",
  },
  getstart_title: {
    position: "absolute",
    zIndex: "999",
    minWidth: "-webkit-fill-available",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    padding: "0px 25px",
    "& h2": {
      fontSize: "30px",
      fontWeight: "600",
      color: "#fff",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        fontSize: '20px',
    },
    },
    "& button": {
      backgroundColor: "#c0e341 !important",
      color: "#000",
      textTransform: "capitalize",
      padding: "12px 15px",
      fontWeight: "600",
    },
  },
  step_section: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    marginBottom: "70px",
  },
  step_title: {
    marginBottom: "30px",
    "& h2": {
      fontSize: "30px",
      fontWeight: "600",
      color: "#0D0D0D",
      [theme.breakpoints.down("sm")]: {
        fontSize: '20px',
    },
    },
    "& p": {
      fontWeight: "500",
      color: "#787878",
      marginBottom: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: '13px',
        marginBottom: "0px",
    },
    },
  },
  first_step: {
    "& h6": {
      color: "#000",
      marginBottom: "10px",
    },
    "& span": {
      color: "#79e696",
    },
    "& p": {
      color: "#666",
      fontSize: "14px",
    },
  },
  search_step: {
    width: "100px",
    height: "100px",
    borderRadius: "100px",
    border: "1px solid #333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
    "& svg": {
      fontSize: "35px",
    },
  },
  order_section: {
    backgroundColor: "#aac810",
    padding: "35px 0px",
    marginBottom: "75px",
    "& h3": {
      color: "#fff",
      fontSize: "25px",
      fontWeight: "500",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: '20px',
      },
    },
    "& p": {
      color: "#fff",
      fontSize:'16px',
      marginBottom: "15px",
      [theme.breakpoints.down("sm")]: {
        fontSize: '13px',
      },
    },
  },
  order_img: {
    height: "500px",
    position: "absolute",
    right: "-80px",
    top: "-90px",
    "& img": {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      display: 'none',
    },
    [theme.breakpoints.down("md")]: {
      position: "inherit",
      top: "0px",
      marginTop: "50px",
    },
  },
  download_btn: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginTop: "40px",
    "& img": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
  },
}));

export default useStyle;
