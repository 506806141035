import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  flexRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  restaurant_card_box: {
    display: "flex",
    gap: "7px",
    marginBottom: "10px",
  },
  marginBottom_10px: {
    marginBottom: "10px",
  },
  neighbourhood_section: {
    width: "100%",
  },
  neighbourhood_title: {
    marginBottom: "30px",
    textAlign: "start",
    "& h2": {
      fontSize: "30px",
      fontWeight: "600",
      //color: '#000',
      marginBottom: "10px",
      color: "#1e1a18",
    },
    "& p": {
      color: "#5b606e",
      fontSize: "18px",
      fontWeight: "400",
    },
  },
  neighbourhood_tab: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        justifyContent: "start",
        flexWrap: "wrap",
        gap: "15px",
      },

      "& .Mui-selected": {
        color: "#000",
        backgroundColor: "#c0e341",
        fontWeight: "600",
        border: "0",
      },
      "& button": {
        minHeight: "auto",
        border: "1px solid #0D0D0D",
        borderRadius: "50px",
        textTransform: "capitalize",
        color: "#0D0D0D",
        padding: "12px 30px",
        transition: "all 1s ease",
        "&:hover": {
          color: "#000",
          backgroundColor: "#c0e341",
          fontWeight: "600",
        },
      },
    },
    "& span": {
      display: "none",
    },
  },
  restaurant_card: {
    backgroundColor: "#fff",
    padding: "12px",
    // height: '350px',
    borderRadius: "10px",
    border: "1px solid #e8e8e8",
    transition: "all 0.5s ease",
    "&:hover": {
      boxShadow: "0 8px 30px #00000026",
      "& img": {
        // transform:'scale(1.1)'
      },
    },
  },
  restaurant_img: {
    position: "relative",
    overflow: "hidden",
    transition: "all 0.5s ease",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "10px",
      transition: "all 0.5s ease",
    },
    "&:before": {
      content: '""',
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(179deg, #eeeeee00 0%, #0000008a 65%, #000000 100%) 0% 0% no-repeat padding-box",
      position: "absolute",
      top: 0,
      left: 0,
      borderRadius: "10px",
    },
  },
  restaurant_card_content: {
    flex: 1,
    borderBottom: "1px solid #ddd",
    paddingBottom: "12px",
    marginTop: "15px",
    "& h3": {
      fontSize: "18px",
      color: "#333",
      fontWeight: "600",
      marginBottom: "10px",
      //color: "#1e1a18",
    },
    "& p": {
      fontSize: "14px",
      color: "#787878",
      fontWeight: "400",
    },
    "& h6": {
      color: "#7F9801",
      fontSize: "14px",
      maxWidth: "260px",
      fontWeight: "400",
    },
  },
  restaurant_card_footer: {
    display: "flex",
    paddingTop: "15px",
    justifyContent: "space-between",
  },
  delivery_time: {
    display: "flex",
    gap: "5px",
    color: "#5b606e",
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: " #787878",
    },
    "& svg": {
      fontSize: "20px",
    },
  },
  customScrollbar: {
    paddingBottom: "10px" /* Adds space between scrollbar and content */,

    "&::-webkit-scrollbar": {
      height: "5px" /* Height for horizontal scrollbar */,
    },

    "&::-webkit-scrollbar-track": {
      background: "transparent",
      marginBottom:
        "10px" /* Adjusts space below the scrollbar for a cleaner look */,
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      border: "3px solid transparent" /* Add more space around the thumb */,
    },

    "&::-webkit-scrollbar-button": {
      display: "none" /* Hides the scrollbar arrows for a modern look */,
    },
  },
}));

export default useStyle;
