import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import useStyle from './style'

const SolutionNeeds = (props) => {

    const classes = useStyle();

    return (
        <Box className={classes.solutions_steppers}>
            <Container>
                <Box className={classes.solutions_title}>
                    <Typography variant="h2">{props.sectionTitle}</Typography>
                    <Typography>{props.sectionDescription}</Typography>
                </Box>
                <Box className={classes.solutions_section}>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12} md={6} lg={4} xl={4}>
                            <Box className={classes.solutions_step2}>
                                <Box className={classes.solutions_step}>
                                    <img src={props.solutions_home} alt="solutions_home" />
                                </Box>
                                <Typography variant="h6">
                                {props.firstTitle}
                                </Typography>
                                <Typography>
                                {props.firstDescription}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={4} xl={4}>
                            <Box className={classes.solutions_step2}>
                                <Box className={classes.solutions_step}>
                                <img src={props.solutions_boy} alt="solutions_boy" />
                                </Box>
                                <Typography variant="h6">
                                {props.secondTitle}
                                </Typography>
                                <Typography>
                                {props.secondDescription}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={4} xl={4}>
                            <Box className={classes.solutions_step2}>
                                <Box className={classes.solutions_step}>
                                {props.solutions_man && <img src={props.solutions_man} alt="solutions_man" />}
                                <img src={props.solutions_woman} alt="solutions_woman" />
                                </Box>
                                <Typography variant="h6">
                                {props.thirdTitle}
                                </Typography>
                                <Typography>
                                {props.thirdDescription}
                                </Typography>
                            </Box>
                        </Grid>
                      
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default SolutionNeeds