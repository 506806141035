import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    minHeight: "64px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.light,
  },
  font600: {
    fontWeight: 600,
  },
  ml: {
    marginLeft: "8px",
  },
  userTitle: {
    marginLeft: "8px",
    textTransform: "uppercase",
  },
  linkDecoration: {
    textDecoration: "none",
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  brandLogo: {
    [theme.breakpoints.down("sm")]: {
      width: '30px',
      height: '30px'
    },
  },
  titleLogo: {
    fontSize: '20px',
    fontWeight: '600',
    [theme.breakpoints.down("sm")]: {
      fontSize: '14px'
    },
  },
  imageIcon: {
    height: "50px",
    width: "10px",
  },
  iconRoot: {
    textAlign: "center",
    height: "100%",
    display: "inline-flex",
  },
  registerBtn: {
    textTransform: 'capitalize',
    borderRadius: '5px',
    marginLeft: '10px'
  },
  drawerBox: {
    maxWidth: '150px',
    width: '100%',
  }
}));

export default useStyles;
