import {
  useMediaQuery,
  useTheme,
  Box,
  TextField
} from "@mui/material";
import React, { useState } from "react";
import useStyles from "./styles";

function SearchRestaurantHome({ search, setSearch }) {
  const theme = useTheme();
  const extraSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles(extraSmall);

  // Local state to handle input value
  const [inputValue, setInputValue] = useState(search);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setSearch(value); // Update the search state on every change
  };

  return (
    // <Grid container item className={`${classes.root}`}>
    //   <Grid container item xs={12} className={classes.searchContainer}>
    //     <Paper
    //       elevation={0}
    //       style={{
    //         display: "flex",
    //         flex: 1,
    //         alignItems: "center",
    //         padding: "12px",
    //         background: theme.palette.common.white,
    //         borderRadius: 5,
    //       }}
    //     >
    //       <SearchIcon />
    //       <Input
    //         disableUnderline={true}
    //         fullWidth
    //         type="text"
    //         placeholder={t('searchRestaurantPlaceholder')}
    //         value={inputValue}
    //         onChange={(event) => setInputValue(event.target.value)}
    //         inputProps={{
    //           style: {
    //             borderWidth: 0,
    //             textOverflow: "ellipsis",
    //           },
    //         }}
    //       />
    //       {inputValue ? (
    //         <Button onClick={handleClearSearchClick} style={{ maxWidth: "auto" }}>
    //           <HighlightOffIcon
    //             style={{ color: theme.palette.text.secondary }}
    //           />
    //         </Button>
    //       ) : null}
    //       <Button 
    //         onClick={handleSearchClick}
    //         style={{ marginLeft: "8px" }}
    //       >
    //         {t('search')}
    //       </Button>
    //     </Paper>
    //   </Grid>
    // </Grid>

 
     
    <Box
      className={classes.search_stores}
      sx={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
    >
      <TextField
        placeholder="Search store or products"
        value={inputValue}
        onChange={handleInputChange}
        sx={{ 
          '& .MuiInputBase-input': {
            color: 'black', // Change this to your desired text color
          },
          '& .MuiInputLabel-root': {
            color: 'black', // Optional: Change label color
          },
         flexGrow: 1, maxWidth: "455px", marginRight: "10px" }} // Smaller width and margin between button
        size="small" // Make the TextField smaller
      />
      {/* {inputValue ? (
        <Button onClick={handleClearSearchClick} style={{ maxWidth: "auto" }}>
          <HighlightOffIcon
            style={{ color: theme.palette.text.secondary }}
          />
        </Button>
      ) : null}
      <Button
        className={classes.find_store_btn}
        onClick={handleSearchClick}
        sx={{
          backgroundColor: "#c0e341", // Green background
          color: "black", // Black text
          height: "40px", // Make the button smaller
          textTransform: "none", // Disable uppercase text transformation
        }}
      >
        Search by Name
      </Button> */}
    </Box>
    
  );
}

export default React.memo(SearchRestaurantHome);
