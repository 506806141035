import React from "react";
import CustomPoint from "../../components/singlePoint/singlePoint";

const PointsSection = ({ points }) => {
  return (
    <div>
      {points.map((point, index) => (
        <CustomPoint key={index} text={point} />
      ))}
    </div>
  );
};

export default PointsSection;
