import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    marginBottom: "16px",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#333333",
    display: "block",
    marginBottom: "8px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  dropdown: {
    flex: 1,
    maxWidth: '470px',
    height: '40px',
    borderRadius: '8px',
    border: '1px solid #B0B0B0',
    boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.1)',
    padding: '0 12px',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    color: '#333333',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    position: "relative",
    boxSizing: 'border-box',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      height: '38px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
      height: '36px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      height: '34px',
      borderRadius: '6px',
      padding: '0 8px',
    },
  },
  dropdownContent: {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    backgroundColor: "#ffffff",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: 1,
    borderRadius: "0 0 8px 8px",
    overflow: "hidden",
    border: "1px solid #B0B0B0",
    borderTop: "none",
    marginTop: "-1px",
    "& div": {
      padding: "8px 12px",
      borderBottom: "1px solid #E2E2E2",
      fontFamily: 'Poppins, sans-serif',
      fontSize: '15px',
      fontWeight: '400',
      color: '#333333',
      "&:last-child": {
        borderBottom: "none",
      },
      "&:hover": {
        backgroundColor: "#f1f1f1",
        cursor: "pointer",
      },
    },
  },
  arrow: {
    width: "10px",
    height: "10px",
  },
  placeholder: {
    color: '#B0B0B0',
    fontWeight: '300',
  },
  infoIcon: {
    marginLeft: '12px',
    color: 'grey',
    cursor: 'pointer',
  },
}));

export default useStyles;