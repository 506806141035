import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import useStyle from '../../screens/Delivery/style';

const ReusableStepper = ({ sectionTitle, steps }) => {
    const classes = useStyle();

    return (
        <Box className={classes.reusablesteppers}>
            <Container>
                <Box className={classes.howWork_title} textAlign="center">
                    <Typography variant="h2" marginBottom="50px">{sectionTitle}</Typography>
                </Box>
                <Box className={classes.howWork_section}>
                    <Grid container spacing={5}>
                        {steps.map((step, index) => (
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={index} padding="20px">
                                <Box className={index === steps.length - 1 ? classes.last_step : classes.howWork_step2}>
                                    <Box className={classes.howWork_step}>
                                        <Typography variant='h2'>{index + 1}</Typography>
                                    </Box>
                                    <Typography variant="h6">
                                        {step.title}
                                    </Typography>
                                    {step.description.split('. ').map((sentence, i) => (
                                        <Typography 
                                            key={i} 
                                            marginBottom={i < step.description.split('. ').length - 1 ? '16px' : '0'}
                                        >
                                            {sentence.trim()}{i < step.description.split('. ').length - 1 && '.'}
                                        </Typography>
                                    ))}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default ReusableStepper;
