import React from "react";
import HeroHeader from "../../components/HeroHeader/HeroHeader";
import HeadingImg from "../../assets/images/BecomeADriveh.svg";
import Footer from "../../components/HomeScreen/Footer";
import CustomCard from "../../components/CustomCard/CustomCard";
import CheckMark from "../../assets/images/CheckMark.png"
import { LoginHeader } from "../../components/Header";
import { useNavigate } from 'react-router-dom';

const SubmitFormPage = () => {

  const navigate = useNavigate();  // Initialize useNavigate

  const handleButtonClick = () => {
    // Navigate to the home page
    navigate('/');
  };


  return (
    <>
      <LoginHeader showIcon/>
      <HeroHeader headingText={"Email Sent"} imageSrc={HeadingImg} />
      <CustomCard
      imageSrc={CheckMark}
      headingText="Welcome to Hedge"
      descriptionText="We're just checking a few details, you'll receive an email confirmation shortly."
      buttonText="Done"
      onButtonClick={handleButtonClick}
    />
      <Footer />
    </>
  );
};

export default SubmitFormPage;

