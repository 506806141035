import { makeStyles } from "@mui/styles";

const useStyle = makeStyles(theme => ({
    solutions_steppers: {
        backgroundColor: '#F9F9F9',
        padding: '50px',
        margin:'75px 0',
        marginBottom:'0px',
        [theme.breakpoints.down("sm")]: {
            padding: '20px',
        },
    },
    solutions_title: {
        marginTop: '50px',
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: {
          marginBottom:'50px'
        },
        "& h2": {
            fontSize: "30px",
            fontWeight: "600",
            color: "#1e1a18",
        },
        "& p": {
            fontWeight: "400",
            color: "#787878",
            marginBottom: "10px",
            fontSize:'14px',
             [theme.breakpoints.down("xl")]: {
                marginBottom:'88px',
            },
            [theme.breakpoints.down("lg")]: {
                marginBottom:'70px',
            },
            [theme.breakpoints.down("md")]: {
                marginBottom:'50px',
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: '13px',
                marginBottom:'30px',
            },
        },
    },
    solutions_section: {
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        marginBottom: "70px",
        [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
            gap: "0px",
            marginBottom: "0px",
        },
    },
    solutions_step2: {
        "& h6": {
            color: "#4B4B4B",
            fontSize: "24px",
            fontWeight: "500",
            [theme.breakpoints.down("sm")]: {
                fontSize: "18px",
              },
        },
        "& p": {
            color: "#757575",
            fontSize: "14px",
            [theme.breakpoints.down("sm")]: {
                marginBottom:'30px',
                fontSize: '13px',
              },
        },
    },
    solutions_step: {
       "& img":{
            width:'70px',
            height:'70px',
       },
        "& svg": {
            fontSize: "35px",
        },
        "& h2": {
            color: "#0D0D0D",
            fontSize: "32px",
            fontWeight: "200",
        },
    },
    last_step: {
        position: 'relative',
        [theme.breakpoints.down("md")]: {
            marginBottom: "15px",
        },
        "& h6": {
            color: "#4B4B4B",
            fontSize: "24px",
            fontWeight: "500",
        },
        "& p": {
            color: "#666",
            fontSize: "14px",
        },
    },
}))

export default useStyle