const km = {
  translation: {
    selectLanguage: "ជ្រើសរើស​ភាសា",
    saveButton: "រក្សាទុក",
    footerText:
      "Hedge គឺជាវេទិកាគ្រប់គ្រងការចែកចាយប្រភពបើកចំហសម្រាប់ អនាគត។ យើងផ្តល់អាទិភាពដល់ការច្នៃប្រឌិត ភាពបត់បែន និងតម្លៃសមរម្យ។ និងផ្តល់ជូននូវដំណោះស្រាយដែលអាចធ្វើមាត្រដ្ឋានបាន និងអាចប្ដូរតាមបំណងបាន ដែលសម្រួលដល់ដំណើរការរបស់អ្នក។ ដំណើរការចែកចាយ។",
    footerLinkHome: "ទំព័រដើម",
    footerLinkPP: "គោលគ្រឿងបទនិងលក្ខខណ្ឌ",
    footerLinkTC: "លក្ខខណ្ឌ និងលក្ខណៈ",
    footerEndText: "Hedge - © 2022 រក្សាសិទ្ធិទាំងអស់",
    followUs: "តាមដានពួកយើង",
    poweredBy: "ដោនឡូតដោយ",
    linksTitle: "តំណ",
    subTotal: "សរុបរង",
    deliveryFee: "ថ្លៃសេវាដឹកជញ្ជូន",
    haveVoucher: "តើអ្នកមានប័ណ្ណទូទាត់ទេ?",
    remove: "យកចេញ",
    change: "ផ្លាស់ប្តូរ",
    taxFee: "ការគិតពន្ធ",
    total: "សរុប",
    tip: "គុណភាព",
    discount: "បញ្ចុះតម្លៃ",
    name: "ឈ្មោះ",
    phone: "ទូរស័ព្ទ",
    email: "អ៊ីមែល",
    address: "អាសយដ្ឋាន",
    changeAddress: "ផ្លាស់ប្តូរអាសយដ្ឋាន",
    contactInfo: "ព័ត៌មានទំនាក់ទំនង",
    paymentMethod: "វិធី​សា​ស្រ្ត​ទូទាត់",
    orderBtn: "ដាក់បញ្ជាទិញ",
    deliveryTime: "ពេលវេលាដឹកជញ្ជូន",
    delivery: "ការដឹកជញ្ជូន",
    searchRestaurantPlaceholder: "ស្វែងរកភោជនីយដ្ឋាន និងម្ហូប",
    allRestaurant: "ភោជនីយដ្ឋានទាំងអស់។",
    pending: "កំពុងរង់ចាំ",
    accepted: "បានទទួលយក",
    assigned: "បានបញ្ជាក់",
    picked: "បានយក",
    delivered: "បានដឹកជញ្ជូន",
    completed: "បានបញ្ចប់",
    titleOrders: "ការបញ្ជាទិញរបស់ខ្ញុំ",
    titleProfile: "ប្រវត្តិរូប",
    titleSettings: "ការកំណត់",
    titleHelp: "ជំនួយ",
    titleChat: "ជជែក",
    titleLogout: "ចាកចេញ",
    passwordInfo: "ព័ត៌មានពាក្យសម្ងាត់",
    myAddresses: "អាសយដ្ឋានរបស់ខ្ញុំ",
    edit: "កែប្រែ",
    creditDebitCard: "ប័ណ្ណឥណទាន / ប័ណ្ណសម្រាប់បង់ប្រាក់",
    paypal: "ប៉ាយប៉ាល់",
    cash: "សាច់ប្រាក់",
    deliverTo: "ដឹកជញ្ជូន​ទៅ",
    ASSIGNED: "បានគោរព",
    PENDING: "កំពុងរងចាំ",
    PICKED: "បានយក",
    ACCEPTED: "បានទទួលយក",
    DELIVERED: "បានដឹកជញ្ជូន",
    COMPLETED: "បានបញ្ចប់",
    orderPlaced: "បានបញ្ជាទិញ",
    "Order ID": "លេខ​លំដាប់",
    "Order status: PENDING": "ស្ថានភាពការបញ្ជាទិញ៖ កំពុងរង់ចាំ",
    "Order status: ACCEPTED": "ស្ថានភាពការបញ្ជាទិញ៖ យល់ព្រម",
    "Order status: ASSIGNED": "ស្ថានភាពបញ្ជាទិញ៖ បានកំណត់",
    "Order status: PICKED": "ស្ថានភាពការបញ្ជាទិញ៖ ជ្រើសរើស",
    "Order status: DELIVERED": "ស្ថានភាពការបញ្ជាទិញ៖ ប្រគល់",
    "Order status: Order Placed": "ស្ថានភាពបញ្ជាទិញ៖ បញ្ជាទិញ",
    deliveryAddress: "អាស័យ​ដ្ឋាន​សំរាប់​ការ​ដឹកជញ្ជូន",
    orderDetail: "លំអិតលំដាប់",
    orderFrom: "ការបញ្ជាទិញ​របស់អ្នកពី",
    orderNo: "លេខកូដបញ្ជាទិញ​របស់អ្នក",
    findRestaurants: "ស្វែងរកភោជនីយដ្ឋាន",
    putUsInYourPocket: "ដាក់​យើង​ក្នុង​ការណែនាំ​របស់អ្នក។",
    containerText:
      "ទាំងអស់គឺនៅក្នុងជំហានរបស់អ្នក - ភោជនីយដ្ឋានដែលអ្នកចូលចិត្ត។ ស្វែងរកអាហារត្រឹមត្រូវដើម្បីប្រវត្តិរូបសង្ខេបរបស់អ្នក និងធ្វើឲ្យភាពជឿជាក់ដែលចាប់ផ្តើមត្រូវនៅមកហើយ។ ទៅមើលអ្នកអាចទាញយកយើងហើយតែសូម។",
    findingAddress: "រកអាសយដ្ឋានដោយប្រើប្រាស់ការបញ្ចូលទីតាំង GPS",
    zonesFunctionality: "អនុគមន៍តំបន់សម្រាប់ភោជនីយដ្ឋាន និងអ្នកបើកបរ",
    multipleRestaurants: "មុខងារបន្ថែមភោជនីយដ្ឋានច្រើន",
    realTimeOrder: "ការធ្វើបច្ចុប្បន្នភាពការទទួលបានការអាប់ដេតនាពេលវេលាពេលព្រឹក",
    differentSections: "មុខងារជំពាក់សំណួរសំខាន់សម្រាប់ការអាប់ដេតភោជនីយដ្ឋាន",
    previousOrder: "ប្រវត្តិការបញ្ជាទិញចាស់ និងបន្ថែមភោជនីយដ្ឋានដែលចូលចិត្ត",
    ourDelivery:
      "ប្រព័ន្ធគ្រប់គ្រងការដឹកជញ្ជូនរបស់យើងត្រូវបានរចនាសម្រាប់អនាគតពីអសន្និរក",
    builtOnCommunity: "បានស្នើរស្នេហ៍លើប្រភេទគោលបំណងដែលបានទាក់ទងដោយសហគម",
    yourCart: "រទេះរបស់អ្នក",
    startAdding: "ចាប់ផ្តើមបន្ថែមធាតុទៅក្នុងរទេះរបស់អ្នក",
    goToCheckout: "ទៅកាន់ការបង់ប្រាក់",
    activeOrders: "ប្រវត្តិការដែលកំពុងដំណើរការ",
    pastOrders: "ប្រវត្តិការណែនាំចាស់",
    deliveringTo: "កំពង់ទុកទៅ",
    restaurantDeliver:
      "យើងខុសស្នើសុំទៅកាន់ភោជនីយដ្ឋានដើម្បីស្វែងយល់ថាតើការដឹកជញ្ជូនអាហាររបស់អ្នកនឹងចំនួនប៉ុន្មាន។",
    riderDeliver:
      "យើងខុសស្នើសុំទៅកាន់អ្នកបើកបរដើម្បីស្វែងយល់ថាតើការដឹកជញ្ជូនអាហាររបស់អ្នកនឹងចំនួនប៉ុន្មាន។",
    orderPending: "ការបញ្ជាទិញរបស់អ្នកនៅលើកំពុងរងចាំ។",
    areYouSure: "តើ​អ្នក​ប្រាកដ​ឬទេ?",
    clearCartText:
      "ដោយ​ផ្លាស់​ប្តូរ​ភោជនីយដ្ឋាន​ភ្ជាប់, វានឹងត្រូវ​បានលុបចោល​ពីកន្លែងទំនិញដែលអ្នកបានបន្ថែម​ទៅក្នុង​រទេះ",
    ok: "ព្រម",
    cancel: "បោះបង់",
    submit: "ដាក់ស្នើ",
    titleFavourite: "ចូលចិត្ត",
    titleEmptyFav: "មិនមានរក្សាទុកសំណព្វ",
    emptyFavDesc:
      "អ្នកនឹងឃើញចំណូលចិត្តទាំងអស់របស់អ្នកនៅទីនេះដើម្បីធ្វើឱ្យការបញ្ជាទិញកាន់តែលឿន។",
    emptyFavBtn: "តោះស្វែងរកចំនូលចិត្តមួយចំនួន",
    exactLocation: "តើនេះគឺជាទីតាំងរបស់អ្នកដែរទេ?",
    yourArea: "បញ្ចូលតំបន់របស់អ្នក",
    currentLocation: "ប្រើទីតាំងបច្ចុប្បន្ន",
    deliveryDetails: "ព័ត៌មានលម្អិតចាំបាច់ -e. ជាន់ / អាគារ",
    deliveryDetailError: "តម្រូវឲ្យមានព័ត៌មានការដឹកជូន",
    currentPassword: "ពាក្យសម្ងាត់បច្ចុប្បន្ន",
    newPassword: "ពាក្យសម្ងាត់ថ្មី",
    deliver: "ដឹកជូន",
    time: "ពេល",
    pickup: "ជ្រើសរើសពេល",
    selectTime: "ព្រឹក",
    am: "ព្រឹក",
    pm: "ល្ងាច",
    set: "កំណត់",
    apply: "អនុវត្ត",
    applyAVoucher: "អនុវត្តប័ណ្ណ",
    readAll: "អាន​ទាំងអស់",
    readMore: "អាន​បន្ត",
    demo: "បង្ហាញលក្ខណៈ",
    getQuote: "ទទួល​សេចក្ដី​បញ្ជា",
    restaurantCloseText:
    "ភោជនីយដ្ឋានកំពុងបិទបច្ចុប្បន្ននេះ។ សូមពិនិត្យមើលភោជនីយដ្ឋានផ្សេងទៀតដែលបើកឬមើលមនុស្សមើលទំព័រដើម្បីរៀបចំការបញ្ជាទិញដូចខាងក្រោម។",
    seeOtherRestaurants: "មើលភោជនីយដ្ឋានផ្សេងទៀត",
    goToMenu: "ទៅកាន់ម៉ឺន",
    deliveryHours: "ម៉ោងដឹកជូន",
    about: "អំពី",
    reviews: "ពិនិត្យឡើង",
    closedAllDay: "បិទលក់រាល់ថ្ងៃ",
    minute: "នាទី",
    welcome: "សូមស្វាគមន៍!",
    signUpOrLogin: "ចុះឈ្មោះ ឬ ចូលគណនីដើម្បីបន្ត",
    signInWithGoogle: "បន្តជាមួយគម្រោង Google",
    or: "ឬ",
    continueWithEmail: "បន្តជាមួយអ៊ីម៉ែល",
    terms: "លក្ខខណ្ឌ និង លក្ខណៈអនុញ្ញាត",
    privacyPolicy: "គោលការណ៍ភាពឯកជន",
    bySigningUp: "ដោយការចុះឈ្មោះអ្នកទទួលស្គាល់ថាអ្នកទទួលយកទិន្នន័យរបស់យើងយើង",
    and: " និង ",
    whatsYourEmail: "អ្នកមានអ៊ីមែលអ្វីខ្លះ?",
    checkAccount: "យើងនឹងពិនិត្យមើលថាអ្នកមានគណនីឬឬទេ",
    continue: "បន្ត",
    invalidEmail: "អ៊ីម៉ែលមិនត្រឹមត្រូវ",
    letsGetStarted: "តោះចាប់ផ្តើម!",
    createAccount: "បង្កើតគណនីរបស់អ្នកនៅលើ Hedge",
    firstnameErr1: "ទីតាំងគោលត្រាចាំបាច់!",
    firstnameErr2: "ឈ្មោះជានិច្ចរាយ!",
    lastnameErr1: "នាមត្រកូលត្រាចាំបាច់!",
    lastnameErr2: "នាមជានិច្ចរាយ!",
    mobileErr1: "លេខទូរស័ព្ទត្រូវបានចាំបាច់!",
    mobileErr2: "លេខទូរស័ព្ទមិនត្រឹមត្រូវ!",
    emailErr2: "អ៊ីម៉ែលមិនត្រឹមត្រូវ",
    passwordErr1:
      "លេខសំងាត់មិនត្រឹមត្រូវ។ លេខសំងាត់ត្រូវតែមានតួអក្សរធំ 1 តួអក្សរតូច 1 និងលេខ 1 យ៉ាងតិច។",
    passwordErr2: "លេខសំងាត់មិនត្រឹមត្រូវ",
    generalErr: "មាន​អ្វី​ខុស​ប្រូហ្វាល",
    verifyEmail: "ធ្វើអោយអ៊ីម៉ែលរបស់អ្នកត្រូវបានផ្ទៀងផ្ទាត់",
    enterOtp: "សូមបញ្ចូល OTP ដែលយើងបានផ្ញើទៅអ៊ីម៉ែលរបស់អ្នកដោយប្រើទម្រង់ថ្មី",
    enterOtpPhone : "សូមបញ្ចូល OTP ដែលយើងបានផ្ញើទៅអ៊ីម៉ែលរបស់អ្នកដោយប្រើទម្រង់ថ្មី",
    invalidCode: "កូដមិនត្រឹមត្រូវ។ សូមពិនិត្យមើលហើយបញ្ចូលម្ដងទៀត",
    resendCode: "ផ្ញើម្ដងទៀតកូដ",
    retryAfter: "ព្រមាន​ម្ដង​ទៀត បន្ទាប់ពី",
    loginBtn: "ចូល",
    addToCart: "បន្ថែមទៅកន្ត្រក",
    customize: "កំណត់​ប្រៀបធៀប",
    optional: "ជ្រើស​រើស​បាន",
    required: "ត្រូវ​ការ",
    specialInstructions: "ការ​ណែនាំ​ពិសេស",
    anySpecific:
      "មាន​ចង់​អនុញ្ញាត​អ្វី​មួយ​ទៀតដែរទេ? ឲ្យ​មើល​ពិសេស​ទៅ​បាន​គឺ​បាន។",
    selectVariation: "ជ្រើស​រើស​ការ​ផ្លាស់​ប្ដូរ",
    closed: "បិទ",
    new: "ថ្មី",
    orderCancelled: "ការបញ្ជាទិញរបស់អ្នកត្រូវបានលុបចោល",
    orderCompleted:
      "ការបញ្ជាទិញបានបញ្ចប់ដោយជោគជ័យ។ សូមអរគុណចំពោះការបញ្ជាទិញរបស់អ្នក",
    reorder: "កម្មវិធីការបញ្ជាទិញឡើងវិញ",
    review: "ពិនិត្យឡើងវិញ",

    subTitle: "ជ្រើសរើស ១",
    placeholder: "ឧបករណ៍ មិនមាចវ៉ាអ៊ី",
    phoneNumMissing: "លេខទូរស័ព្ទបានខុសបាត់",
    updatePhone: "ធ្វើបច្ចុប្បន្នភាពទូរស័ព្ទរបស់អ្នក",
    number: "លេខទំនេរ?",
    secureAcc: "យើងត្រូវការវាយបញ្ចូលនេះដើម្បីបង្កើតគណនីរបស់អ្នក",
    verifyPhone: "ផ្ទៀងផ្ទាត់ទូរស័ព្ទរបស់អ្នក",
    skipNow: "រំលងឥឡូវនេះ",
    favouriteListUpdated: "បានធ្វើឲ្យបានបន្ថែមចំនួនរបស់អ្នកបញ្ជាទិញបានទំនិញទើប",
    pendingText: "កំពុងរង់ចាំការឆ្លើយពី",
    acceptedText: "ពេលធ្វើរការពិសេស",
    orderLateText: "សុំទោស! ការបញ្ជាទិញ​របស់​អ្នក​មាន​តម្រូវ​ជាមួយ​នៅ​រយះ​ពេល។",
    riderPickText:
      "អ្នក​ប៊ូរ​សំអាតហើយ​នឹង​យក​វា​ឡើង​សំរាប់​អ្នក​នៅ​ពេល​ដែល​វា​រួច​ហើយ។",
    orderIs: "ការបញ្ជាទិញ​របស់​អ្នក​គឺ",
    orderAssigned: "បាន​ផ្ដល់​ទៅ​មក​អ្នក​ប៊ូរ",
    orderAssignedToRider:
      "ការបញ្ជាទិញ​របស់​អ្នក​ត្រូវបាន​ផ្ដល់​ទៅលើ​បដិសណ្ឋាន​របស់យើង​ហើយ​នឹង​ត្រូវបាន​យក​ឡើង​សំរាប់​អ្នក​នៅ​ពេល​ដែល​វា​រួច​ហើយ។",
    riderOnWay: "អ្នក​ប៊ូរ​របស់​អ្នក​គឺ​នៅ​លើ​ផ្លូវ។",
    orderHasBeen: "ការបញ្ជាទិញ​របស់​អ្នក​ត្រូវ​បាន",
    enjoyYourMeal: "សូមសរសើរដល់ការបញ្ជាទិញរបស់អ្នក!",
    cancelled: "បោះបង់",
    personalDetail: "ព័ត៌មានផ្ទាល់ខ្លួន",
    voucherCode: "លេខ​កូដ​ប៉ាន់ស្សារ",
    pickUp: "យកមកដល់",
    warning: "ប្រុងប្រយ័ត្ន",
    warningText: "មានមួយឬច្រើនធាតុមួយចំនួនមិនមានទេ",
  },
};
export default km;
