// src/components/HeadingMoneyRow/styles.js

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  headingMoneyRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '30px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  money: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#0A0D13',// Customize the color for the money component if needed
  },
}));

export default useStyles;
