import React from "react";
import { Typography } from "@mui/material";

const SectionHeading = ({ text }) => {
  return (
    <Typography variant="h6" sx={{color: 'black', fontWeight: 'bold', marginBottom: '10px'}}>
      {text}
    </Typography>
  );
};

export default SectionHeading;
