import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "linear-gradient(42.09deg, #B9DE31 22.23%, #C8EA19 77.77%)",
    // padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    borderRadius: "16px", // Rounded corners
    maxWidth: "1000px", // Smaller overall size
    alignItems: "center",
    margin: "80px auto", // Centering horizontally
    padding: "60px",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1000px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Switch to column layout on small screens
      alignItems: "center",
      textAlign: "center", // Center text in column layout
    },
  },
  textContainer: {
    flex: 1,
    paddingRight: theme.spacing(2),
    textAlign: "left", // Align text to the left
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      marginBottom: theme.spacing(2), // Add margin below text in column layout
    },
  },
  heading: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    fontSize: "1.25rem", // Smaller heading size
    marginBottom: theme.spacing(0.5),
  },
  description: {
    color: theme.palette.common.black,
    fontSize: "0.875rem", // Smaller description size
  },
  buttonContainer: {
    display: "flex",
    gap: theme.spacing(2),
    marginLeft: "50px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Stack buttons in column layout on small screens
      marginLeft: 0, // Remove left margin in column layout
    },
  },
  button: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(1.5, 3), // Bigger button size
    fontSize: "1rem", // Larger text in buttons
    "&:hover": {
      backgroundColor: theme.palette.grey[800],
    },
  },
}));

export default useStyles;
