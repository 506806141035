import { makeStyles } from "@mui/styles";

const useStyle = makeStyles(theme => ({
    accordionTitle:{
        fontSize:'30px',
        fontWeight:'500',
        color: theme.palette.common.bgColor,
        marginBottom:'20px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
          },
    },
}))

export default useStyle;