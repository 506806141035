import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from 'react-i18next';
import FormCard from "../../components/FormCard/FormCard";
import SingleTextField from "../../components/Textfield/TextField";
import DoubleTextFieldRow from "../../components/DoubleTextField/DoubleTextField";
import PhoneTextField from "../../components/PhoneTextField/PhoneTextField";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import TermsAndConditions from "../../components/Terms&Condition/Terms&Condition";
import useRegistration from "../../hooks/useRegistration";
import GoogleSrc from "../../assets/images/GoogleSignIn.png";
import ConfigurableValues from "../../config/constants";
import GoogleLogin from "react-google-login";
import Footer from "../../components/HomeScreen/Footer";
import { LoginHeader } from "../../components/Header";
import HerosectionLoginPages from "../../components/HeroSectionLoginPage/HeroSectionLoginPage";
import BecomeADriver from "../../assets/images/BecomeADriveh.svg";
import PlaceholderComponent from "../../components/Placeholder/Placeholder";
import CustomerSignUp from "../../assets/images/CustomerSignUpMid.png";
import GoogleConnectButton from "../../components/GoogleSignIn/GoogleSignIn";

const Registration = () => {
  const { GOOGLE_CLIENT_ID } = ConfigurableValues();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    goolgeSuccess,
    authenticationFailure,
    loading,
    loginButtonSetter
  } = useRegistration(); // Custom hook for registration logic
  const [formLoading, setFormLoading] = useState(false); 
  const [googleLoading, setGoogleLoading] = useState(false);


  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
  });
  const [mainError, setMainError] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phoneNumber, password, confirmPassword, termsAccepted } = formData;

    let validate = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;

    if (!emailRegex.test(email)) {
      setMainError({ type: "error", message: t('emailErr2') });
      validate = false;
    }
    if (!firstName.trim()) {
      setMainError({ type: "error", message: t('firstnameErr2') });
      validate = false;
    }
    if (!lastName.trim()) {
      setMainError({ type: "error", message: t('lastnameErr2') });
      validate = false;
    }
    if (!password) {
      setMainError({ type: "error", message: t('passwordErr2') });
      validate = false;
    }
    if (password !== confirmPassword) {
      setMainError({ type: "error", message: t('passwordMismatchErr') });
      validate = false;
    }
    if (!passRegex.test(password)) {
      setMainError({ type: "error", message: t('passwordErr1') });
      validate = false;
    }
    if (!phoneNumber) {
      setMainError({ type: "error", message: t('mobileErr2') });
      validate = false;
    }
    if (!termsAccepted) {
      setMainError({ type: "error", message: t('termsError') });
      validate = false;
    }

    if (validate) {
      setFormLoading(true);
      try {
        // Replace with your API call logic
        // Example:
        // await yourRegistrationApiCall(formData);
        navigate("/verify-email", {
          replace: true,
          state: {
            email: email.toLowerCase().trim(),
            password,
            name: `${firstName} ${lastName}`,
            phone: `+${phoneNumber}`,
            picture: "",
          },
        });
      } catch (error) {
        setMainError({ type: "error", message: t('generalErr') });
      } finally {
        setFormLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const callGoogle = useCallback(
    (response) => {
      if (!loading) {
        loginButtonSetter("GOOGLE");
        setGoogleLoading(true);
        // Handle Google login response
        if (response?.profileObj) {
          // Process Google login success
          goolgeSuccess(response);
        } else if (response?.error) {
          // Handle Google login failure
          authenticationFailure(response.error);
        }
        // setGoogleLoading(false);
      }
    },
    [loading, loginButtonSetter, goolgeSuccess, authenticationFailure]
  );

  const formComponent = (
    <FormCard heading="Get Started Today!">
      <form onSubmit={handleSubmit}>
        <DoubleTextFieldRow
          placeholder1="First Name"
          name1="firstName"
          value1={formData.firstName}
          placeholder2="Last Name"
          name2="lastName"
          value2={formData.lastName}
          onChange={handleChange}
          required
        />
        <SingleTextField
          placeholder="Email Address"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <PhoneTextField
          placeholder="Enter your phone number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
        <SingleTextField
          placeholder="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          isPassword={true}
          required
        />
        <SingleTextField
          placeholder="Confirm Password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          isPassword={true}
          required
        />
        <TermsAndConditions
          label="I Agree To The Terms And Conditions And Privacy Policy"
          name="termsAccepted"
          checked={formData.termsAccepted}
          onChange={handleChange}
          required
        />
        <SubmitButton label="Submit" type="submit" loading={formLoading} />
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          render={(renderProps) => (
            <GoogleConnectButton
              text="or connect via"
              onClick={renderProps.onClick}
              loading={googleLoading}
              googleLogoSrc={GoogleSrc}
            />
          )}
          onSuccess={callGoogle}
          onFailure={callGoogle}
          cookiePolicy={"single_host_origin"}
        />
      </form>
    </FormCard>
  );

  return (
    <div>
      <LoginHeader showIcon />
      <HerosectionLoginPages
        imageSrc={BecomeADriver}
        headingText="Bring your home to life"
        descriptionText="All your hardware and landscape supplies delivered."
      />
      <PlaceholderComponent
        imageSrc={CustomerSignUp}
        formComponent={formComponent}
      />
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(mainError.type)}
        autoHideDuration={6000}
        onClose={() => setMainError({})}
      >
        <Alert severity={mainError.type} sx={{ width: "100%" }}>
          {mainError.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Registration;
