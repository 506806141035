/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import React, { useCallback, useContext, useEffect } from "react";
import { restaurantList } from "../../apollo/server";
import Footer from "../../components/HomeScreen/Footer";
import { Header, LoginHeader } from "../../components/Header";
import { useLocation } from "react-router-dom";
import { useLocationContext } from "../../context/Location";
import UserContext from "../../context/User";
import Analytics from "../../utils/analytics";
import useStyles from "./styles";
import DetailedOrderCard from "../../components/Orders/DetailedOrderCard/DetailedOrderCard";
import { ACTIVE_STATUS } from "../../utils/constantValues";
import Neighbourhood from "../../components/HomeScreen/Neighbourhood";
import AllStores from "../../assets/images/AllStores.png";
import HerosectionAllStores from "../../components/HeroHeaderAllStores/HeroHeaderAllStores";


const RESTAURANTS = gql`
  ${restaurantList}
`;

function Restaurants() {
  // const navigate = useNavigate();
  const { location, setLocation, setSearchedCity, setSelectedCity } =
    
    useLocationContext();
  const { isLoggedIn } = useContext(UserContext);
  // const [HideShowAll, setHideShowAll] = useState(false);


  const classes = useStyles();
  const _location = useLocation();

  const {
    orders,
    // clearCart,
  } = useContext(UserContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const activeOrders = orders.filter((o) =>
    ACTIVE_STATUS.includes(o.orderStatus)
  );

  const handleLocationChange = useCallback(
    (location) => {

      let cityName = "";
      if (location.address) {
        const addressParts = location?.address?.split(",");
        cityName = addressParts.length > 0 ? addressParts[0].trim() : "";
      }
      setSearchedCity({lat: location.latitude, lng: location.longitude, cityName});
      setLocation(location);

    },
    [setLocation, setSearchedCity]
  );

  useEffect(async () => {
    await Analytics.track(Analytics.events.NAVIGATE_TO_RESTAURANTS);
    setSelectedCity(_location.state.city);
  }, [_location.state]);
    

  const { loading, error } = useQuery(RESTAURANTS, {
    variables: {
      longitude: location?.longitude || null,
      latitude: location?.latitude || null,
      ip: null,
    },
    fetchPolicy: "network-only",
    skip: !location,
  });

  if (loading || error) {
    return (
      <Grid container>
        {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
        {/* <Subheader /> */}

        <Box className={classes.spinnerContainer}>
          {loading ? (
            <CircularProgress color="primary" size={48} />
          ) : (
            <Typography>Unable to load data</Typography>
          )}
        </Box>
      </Grid>
    );
  }

  return (
    <Grid>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      {/* <Subheader onLocationChange={handleLocationChange} /> */}
      <Box className={classes.searchWrapper}>
        <Grid container item>
          <HerosectionAllStores
            imageSrc={AllStores}
            headingText="Hardware and landscaping supplies delivered, from local stores and suppliers"
            onLocationChange={handleLocationChange}
          />
        </Grid>
      </Box>

      {activeOrders.length > 0 ? (
        <Box
          style={{
            backgroundColor: theme.palette.primary,
            padding: mobile ? "10px" : "80px 90px",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            {activeOrders.map((item) => (
              <Grid key={item.id} item sm={12} xl={6} lg={6}>
                {mobile ? null : <DetailedOrderCard key={item._id} {...item} />}
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}

      <Box style={{ width: "100%", minHeight: "100vh", marginTop: "50px", marginBottom: "50px" }}>
        <Neighbourhood fromRestaurant={true} HideShowAll= {false} />
      </Box>
      <Footer />
    </Grid>
  );
}

export default Restaurants;
