import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  textFieldRow: {
    display: 'flex',
    gap: '20px',
    width: '100%',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      gap: '10px',
    },
  },
  textField: {
    width: '100%',
    maxWidth: '240px',
    height: '42px',
    borderRadius: '8px', // Square-like shape with rounded corners
    border: '1px solid #D0D0D0', // Thin border
    padding: '0 15px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    background: '#fffff',
    color: '#333333',
    outline: 'none',
    boxSizing: 'border-box',
    '&::placeholder': {
      fontSize: '16px',
      color: '#D0D0D0', // Thin placeholder text
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      height: '38px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
      height: '36px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      height: '34px',
    },
  },
}));

export default useStyles;
