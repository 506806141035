import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px', // Reduced from 1100px
    margin: '0 auto',
    alignItems: 'center',
    gap: '40px', // Added gap between text and image
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  textSide: {
    flex: '0 1 45%', // Changed from flex: 1 to make it narrower
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
  },
  imageSide: {
    flex: '1', // Changed from flex: 1 to make it narrower
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  roundedImage: {
    width: '85%', // Reduced from 90%
    height: 'auto',
    borderRadius: '10px',
  },
  textImage: {
    width: '60px',
    height: '60px',
    // marginBottom: '10px',
  },
  heading: {
    marginBottom: '10px',
    fontSize: '22px',
    fontWeight: 'bold',
  },
  description: {
    marginBottom: '10px',
    fontFamily: 'Be Vietnam Pro',
    color: '#757575',
    fontSize: '16px',
    '& br': {
      marginBottom: '0.5em',
    },
  },
}));

export default useStyles;