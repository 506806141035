import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column", // Stack vertically on small screens
    },
  },
  leftSide: {
    flex: 1,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    overflowY: "auto", // Add vertical scroll if content overflows
    boxSizing: "border-box", // Include padding and border in element's total width and height
    [theme.breakpoints.down("md")]: {
      padding: "10px", // Adjust padding for small screens
      width: "100%", // Ensure full width on small screens
    },
  },
  // rightSide: {
  //   flex: 1,
  //   backgroundColor: "#D3D3D3",
  //   padding: "20px",
  //   border: "1px solid #D9D9D9",
  //   filter: "contrast(85%) brightness(120%)",
  //   opacity: 0.9,
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "flex-start",
  //   alignItems: "flex-start",
  //   overflowY: "auto", // Add vertical scroll if content overflows
  //   boxSizing: "border-box",
  //   [theme.breakpoints.down("md")]: {
  //     padding: "10px", // Adjust padding for small screens
  //     width: "100%", // Ensure full width on small screens
  //   },
  // },
  privacyText: {
    color: "#A0A0A0",
    fontSize: "12px",
    marginTop: "20px",
    textAlign: "center",
  },
  backLink: {
    alignItems: "center",
    display: "flex",
    color: "black",
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "inter",
    width: "100%",
    marginLeft: "300px",
    marginTop: "40px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0", // Remove left margin on small screens
      justifyContent: "center", // Center-align link on small screens
    },
  },
  backArrow: {
    marginRight: "8px",
  },
  newRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "40px 0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column", // Stack items vertically on small screens
      textAlign: "center",
    },
  },

  textField: {
    flexGrow: 3, // Increase the flex value to make the text field wider
    maxWidth: "70%", // Ensure it doesn't exceed 70% of the container's width
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%", // Full width on smaller screens
      marginBottom: "10px", // Add some margin for spacing
    },
  },
  rightSide: {
    flex: 1,
    backgroundColor: "#F7F7F7",
    padding: "20px",
    border: "1px solid #D9D9D9",
    filter: "contrast(85%) brightness(120%)",
    opacity: 0.9,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto", // Add vertical scroll if content overflows
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      padding: "10px", // Adjust padding for small screens
      width: "100%", // Ensure full width on small screens
    },
  },

  rightSideHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    fontFamily: "Be Vietnam Pro",
    marginTop: "60px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0", // Remove left margin on small screens
      textAlign: "center", // Center-align heading on small screens
    },
  },

  rightSideBox: {
    width: "100%",
    borderRadius: "16px",
    backgroundColor: "#FFFFFF",
    padding: "20px",
    boxSizing: "border-box",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
      maxWidth: "400px",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
      maxWidth: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "10px",
    },
  },

  orderSummaryRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px 0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column", // Stack items vertically on small screens
      textAlign: "center",
    },
  },

  planName: {
    fontSize: "18px",
    fontWeight: "bold",
    flex: 2,
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px",
    },
  },

  radioButtonsContainer: {
    flex: 2,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginBottom: "10px",
    },
  },

  radioButton: {
    margin: "0 10px",
  },

  radioLabel: {
    fontSize: "12px",
  },

  planPrice: {
    fontSize: "18px",
    fontWeight: "bold",
    flex: 1,
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "center", // Center-align on small screens
    },
  },

  separator: {
    width: "100%",
    height: "2px",
    background: "#B0B0B0",
    margin: "20px 0",
  },

  applyButton: {
    width:'100%',
    height: "44px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "4px",
    marginLeft: "10px", // Add space between the text field and button
    "&:hover": {
      backgroundColor: "#333",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%", // Full width on smaller screens
      height: "36px", // Adjust the height on smaller screens
      marginLeft: "0", // Remove the margin on smaller screens
    },
  },
}));

export default useStyles;
