import React from "react";
import { Typography } from "@mui/material";

const SectionDescription = ({ text }) => {
  return (
    <Typography variant="body1" sx={{  color: '#808080', fontFamily: 'Be Vietnam Pro' , marginBottom: '20px', fontSize: '14px'}}>
      {text}
    </Typography>
  );
};

export default SectionDescription;
